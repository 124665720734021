import { useState } from "react";
import Select from "react-select";
import "./DropDetails.css";
import { useSidePanelContext } from "../../../context/SidepanelProvider";

const DropDetails = ({
  // overview,
  prerequisites,
  gettingStarted,
  artifacts,
  resource,
  title,
  authors,
  selectedTitle,
  setSelectedTitle,
  prerequisiteApperance,

  handleDropDetailsHeadings,
}) => {
  const { overview } = useSidePanelContext();
  const [modalHeadings, setModalHeadings] = useState([
    "Overview",
    "Prerequisites",
    "Getting Started",
    "Development Artifacts",
    "Resources",
  ]);

  const [showBotLine, setShowBotLine] = useState(
    Array.from(Array(5), (_, i) => (i === 0 ? true : false))
  );

  const copyToClipboard = (codeText) => {
    navigator.clipboard
      .writeText(codeText)
      .then(() => console.log("done"))
      .catch((error) => console.error("Failed to copy:", error));
  };

  const CodeShell = ({ text, margin }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: margin ? "0px" : "-30px",
        }}
      >
        <div
          className="cli-code"
          style={{
            background:
              "var(--Neutral-Code-Block-Current-Console-header, #3C414A)",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              cursor: "pointer",
              padding: "5px",
            }}
            onClick={() => copyToClipboard(text)}
          >
            <img width={"23px"} src="./icon/copy-square.svg" alt="Copy" />{" "}
          </div>
        </div>
        <div
          className="cli-code-text"
          style={{
            backgroundColor: "#282C34",
            minWidth: "20px",
            minHeight: "40px",
            width: "100%",
            color: "#fff",
            whiteSpace: "nowrap",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            borderRadius: "3px",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            whiteSpace: "break-spaces",
          }}
        >
          <div
            style={{ padding: "15px 10px 10px 10px", wordBreak: "break-word" }}
          >
            <code className="language-shell">{text}</code>
          </div>
        </div>
      </div>
    );
  };
  const Header = ({ darkMode }) => {
    const headerStyles = {
      color: darkMode ? "#fff" : "#333",
      textAlign: "center",
      marginBottom: "40px",
      position: "relative",
      paddingRight: "40px",
    };

    const closeButtonStyles = {
      position: "absolute",
      top: "0",
      right: "0",
      transform: "translate(-50%, 250%)",
      cursor: "pointer",
      color: darkMode ? "#fff" : "#333",
      fontSize: "20px",
    };

    const tabHeaderStyles = (active) => {
      return {
        marginBottom: 0,
        display: "flex",
        flexDirection: "start",

        position: "relative",
        height: "auto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        cursor: "pointer",
        color: active ? "#FFFFFF" : "#D6D6D6",
      };
    };
    const botLine = {
      width: "100%",

      height: "3px",
      backgroundColor: "#50E6FF",
      position: "absolute",
      bottom: "0",

      marginBottom: "-12px",
      borderRadius: "2px",
    };
    const options = modalHeadings.map((title) => ({
      value: title,
      label: title,
    }));

    const handleSelectedRighbarTitle = (selected) => {
      const i = modalHeadings.indexOf(selected.value);
      console.log(i);
      setSelectedTitle(selected);
      handleDropDetailsHeadings(selected.value);
    };

    return (
      <>
        <div style={headerStyles}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3
                style={{
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "25px",
                  color: "White",
                  letterSpacing: "0.6px",
                  marginBottom: "5px",
                  display: "flex",
                  padding: "0px 40px 0 40px",
                  wordBreak: "break-word",
                  textAlign: "left",
                }}
              >
                {title}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  paddingLeft: "40px",
                }}
              >
                {" "}
                {authors.map((author, i) => (
                  <div>
                    <div
                      style={{
                        display: "flex",

                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "350",
                        color: "white",
                        lineHeight: "24px",
                        letterSpacing: "0.7px",
                      }}
                      key={i}
                    >
                      {i === 0 ? "by " : " |"}
                      <span
                        style={{
                          color: "rgb(80, 230, 255)",
                          cursor: "pointer",
                          padding: "0 5px",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "24px",
                          letterSpacing: "0.7px",
                        }}
                      >
                        <a href={author?.Link} target="_blank">
                          {author?.Name}
                        </a>
                      </span>
                      {i === authors.length - 1 ? (
                        <div
                          style={{
                            display: "flex",

                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "350",
                            lineHeight: "24px",
                            letterSpacing: "0.7px",
                          }}
                          key={i}
                        >
                          |
                          <span
                            style={{
                              color: "rgb(80, 230, 255)",
                              cursor: "pointer",
                              padding: "0 5px",
                              fontSize: "14px",

                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "24px",
                              letterSpacing: "0.7px",
                              display: "flex",
                              flexDirection: "row",
                              width: "max-content",
                              gap: "5px",
                            }}
                          >
                            {" "}
                            View In Github
                            <img
                              width={20}
                              height={20}
                              style={{}}
                              src="./icon/github-icon.svg"
                            />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Select
              value={selectedTitle}
              defaultValue={{ value: "Overview", label: "Overview" }}
              onChange={handleSelectedRighbarTitle}
              options={options}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.hasValue ? "#242424" : "#242424",

                  width: "calc(100% - 66px)",

                  marginLeft: "40px",
                  marginTop: "15px",
                  boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                  border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",
                  textAlign: "left",
                  borderRadius: "10px",
                  ...(state.selectProps.menuIsOpen && {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }),
                  "&:hover": {
                    background: "var(--Neutral-Background-16-Hover, #383838)",
                    borderColor: "#666",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  background: "#242424",
                  color: "#ffffff",
                  marginLeft: "40px",
                  width: "calc(100% - 66px)",
                  marginTop: 0,
                  borderColor: "white",

                  outline: "",
                  boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                  border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "16px",
                  textAlign: "left",
                  borderRadius: "0px 0px 10px 10px",
                }),
                menuList: (provided) => ({
                  ...provided,
                  borderRadius: "0px 0px 10px 10px",
                  paddingBottom: "none",
                  paddingTop: "none",
                }),
                option: (defaultStyles, state) => ({
                  ...defaultStyles,

                  backgroundColor: state.isSelected ? "#474747" : "#242424",

                  "&:hover": {
                    backgroundColor:
                      "var(--Neutral-Background-16-Hover, #383838)",
                  },
                }),
                input: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(-180deg)"
                    : null,
                  transition: "transform 0.3s",
                  color: "#ffffff",
                  "&:hover": {
                    color: "#ffffff",
                  },
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "white",
                  primary: "white",
                },
              })}
            />
          </div>
        </div>
      </>
    );
  };

  const Body = ({ darkMode }) => {
    const bodyStyles = {
      color: darkMode ? "#fff" : "#333",
      padding: "40px",
      paddingTop: "0",
      color: "white",
      height: "771px",
      overflow: "scroll",
    };

    const DropDetailsDisclaimer = () => {
      return (
        <div className="dropDetails-disclaimer">
          <img
            src="./icon/disclaimer-info.svg"
            style={{ marginTop: "2px", maxWidth: "-webkit-fill-available" }}
          />
          <div className="dropDetaials-disclaimer-body">
            <span
              style={{
                fontWeight: "700",
              }}
            >
              Disclaimer:{" "}
            </span>
            Please carefully review the{" "}
            <a
              style={{
                color: "var(--Neutral-Callout-Hyperlink, #50E6FF)",

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "-0.14px",
                cursor: "pointer",
              }}
              href="https://github.com/Azure/arc_jumpstart_drops/blob/main/DISCLAIMER.md"
              target="_blank"
            >
              disclaimer page
            </a>{" "}
            before contributing or using any Jumpstart Drops to help you
            understand the risks and limitations associated with the use of all
            Drops.
          </div>
        </div>
      );
    };

    const DropDetailsNote = ({ description }) => {
      return (
        <blockquote
          style={{
            backgroundColor: "#2F3646",
            color: "#B2CDFF",
            // color: "green",
            paddingLeft: "10px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            padding: "14px 10px",
            margin: "0 0 10px 0",
            borderRadius: "10px",
            marginTop: "8px",
            marginLeft: "-30px",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              gap: "8.708px",
            }}
          >
            <img src="./icon/note-info.svg" style={{}} />
            <div>
              <span
                style={{
                  fontWeight: "700",
                }}
              >
                Note:{" "}
              </span>
              <span style={{ marginLeft: "" }}>{description}</span>
            </div>
          </span>
        </blockquote>
      );
    };

    return (
      <div style={bodyStyles}>
        {selectedTitle?.value === "Overview" ? (
          <div className="overview">
            <div className="overiew-img">
              <img
                src={`${
                  overview.cover ? overview.cover : "./icon/img-icon.svg"
                }`}
                style={{ maxWidth: "-webkit-fill-available" }}
              />
            </div>
            <div className="overview-body">
              <div className="overview-title"> Drop details</div>
              <div className="overview-description">
                {overview.description ? overview.description : "Description"}
              </div>
            </div>
            <DropDetailsDisclaimer />
          </div>
        ) : selectedTitle?.value === "Prerequisites" ? (
          <div className="prerequisites">
            <div>
              <div className="prerequsitie-title">Prerequsites</div>
              {prerequisiteApperance === "Steps" ? (
                <ol
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingInlineStart: "30px",
                  }}
                >
                  {prerequisites.map((prerequisite, i) => (
                    <li key={i} className="prerequsitie-text">
                      {" "}
                      <div>
                        {prerequisite.title
                          ? prerequisite.title
                          : "prerequisite"}
                      </div>
                      {prerequisite.type === "code" ? (
                        <div style={{ margin: "5px 0px 5px 0px" }}>
                          <CodeShell text={prerequisite.description} />
                        </div>
                      ) : prerequisite.type === "note" ? (
                        <DropDetailsNote
                          description={prerequisite.description}
                        />
                      ) : null}
                    </li>
                  ))}
                </ol>
              ) : (
                <ul
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingInlineStart: "30px",
                  }}
                >
                  {prerequisites.map((prerequisite, i) => (
                    <li key={i} className="prerequsitie-text">
                      {" "}
                      <div>
                        {prerequisite.title
                          ? prerequisite.title
                          : "Prerequisite"}
                      </div>
                      {prerequisite.codeType === "code" && (
                        <div style={{ margin: "5px 0px 5px 0px" }}>
                          <CodeShell text={prerequisite.code} />
                        </div>
                      )}
                      {prerequisite.noteType === "note" && (
                        <DropDetailsNote description={prerequisite.note} />
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <DropDetailsDisclaimer />
          </div>
        ) : selectedTitle.value === "Getting Started" ? (
          <div className="getting-started">
            <div className="getting-started-body">
              <div className="getting-started-title">Getting started</div>
              <div className="getting-started-subheader">
                {gettingStarted.title ? gettingStarted.title : "Sub header"}
              </div>

              <div className="getting-started-discreption">
                {gettingStarted.description
                  ? gettingStarted.description
                  : "Description"}
              </div>
              <ul
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  paddingInlineStart: "30px",
                }}
              >
                {gettingStarted.textType === "text" ? (
                  gettingStarted.texts.map((text, i) => (
                    <li className="getting-started-text" key={i}>
                      {text ? text : "Text"}
                    </li>
                  ))
                ) : (
                  <CodeShell text={gettingStarted.code} />
                )}
              </ul>
            </div>
            <DropDetailsDisclaimer />
          </div>
        ) : selectedTitle.value === "Development Artifacts" ? (
          <div className="development-artifacts">
            <div className="development-artifacts-body">
              <div className="development-artifacts-subheader">
                {artifacts.title ? artifacts.title : "Sub header"}
              </div>
              <div className="development-artifacts-description">
                {artifacts.description ? artifacts.description : "Description"}
              </div>
            </div>
            {artifacts.contentType === "code" ? (
              <CodeShell text={artifacts.code} margin={true} />
            ) : (
              <img
                src={`${
                  artifacts.content ? artifacts.content : "./icon/img-icon.svg"
                }`}
              />
            )}
            <DropDetailsDisclaimer />
          </div>
        ) : (
          <div className="resource">
            <div>
              <div className="resource-title">Resources</div>
              <div className="resource-description">
                {resource.description ? resource.description : "Description"}
              </div>

              {resource.textType === "text" && (
                <ul
                  style={{
                    marginTop: "0px",
                    marginBottom: "5px",
                    paddingInlineStart: "30px",
                  }}
                >
                  {resource.texts.map((text, i) => (
                    <li
                      className="resource-text"
                      key={i}
                      style={{ color: resource.textsLink[i] && "#50E6FF" }}
                    >
                      {text ? text : "Text"}
                    </li>
                  ))}
                </ul>
              )}

              {resource.fileType === "file" && (
                <img
                  src={`${
                    resource.file ? resource.file : "./icon/img-icon.svg"
                  }`}
                  style={{ maxWidth: "-webkit-fill-available" }}
                />
              )}
            </div>
            <DropDetailsDisclaimer />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="side-panel-body">
        <Header />
        <Body />
      </div>
    </>
  );
};

DropDetails.defaultProps = {
  title: "Title",
  authors: [{ Name: "Author", Link: "" }],
};

export default DropDetails;
