import { useEffect, useState } from "react";
import "./DropCard.css";
import Popover from "@mui/material/Popover";

const DropCard = ({ cardData }) => {
  const { Title, Summary, Authors, Products, LastModified, Source } = cardData;

  const [anchorEl, setAnchorEl] = useState(null);
  let [openRightSidebar] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="drop-card" id="drop-card-body" tabIndex={0} role="button">
      <div className="">
        <div className="top-content">
          <div className="header">
            <div className="header-left">
              <div>
                <img src="./images/drops/dropcard/downloads.svg" alt="" />
                --
              </div>
              <div>
                <img src="./images/drops/dropcard/eye.svg" alt="" />
                --
              </div>
            </div>
            <div className="header-right header-right-new">
              <div style={{ position: "relative" }}>
                <img src="./images/drops/dropcard/new.svg" alt="" />
                <img
                  className="hovered-save"
                  src="./images/drops/dropcard/new-hover.svg"
                  alt=""
                />
                NEW
              </div>
            </div>
          </div>
          <div className="drop-body">
            <h3 style={{ wordBreak: "break-word" }}>{Title}</h3>
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                flexWrap: "wrap",
              }}
            >
              <span>
                {" "}
                by{" "}
                <a href={Authors[0].Link} target="_blank">
                  <span style={{ color: "#50E6FF", cursor: "pointer" }}>
                    {" "}
                    {Authors[0].Name}
                  </span>
                </a>
              </span>

              {Authors.length > 1 ? (
                <>
                  and{" "}
                  <a
                    onClick={(e) => {
                      openRightSidebar = false;
                      handleClick(e);
                    }}
                  >
                    {" "}
                    <span style={{ color: "#50E6FF", cursor: "pointer" }}>
                      {" "}
                      {`${Authors.length - 1} ${
                        Authors.length - 1 > 1 ? "others" : "other"
                      }`}
                    </span>
                  </a>
                  <Popover
                    sx={{
                      "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                        backgroundColor: "#242424",
                        color: "#fff",
                        position: "absolute",
                        padding: "5px",
                        boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                      },
                    }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => {
                      openRightSidebar = false;
                      handleClose();
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <span
                      style={{
                        width: "256px",
                        height: "35px",
                        padding: "0px 0px 0px 15px",
                        alignItems: "center",
                        gap: "7px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#ADADAD",
                          fontFamily: "Segoe UI",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        {" "}
                        Additional authors
                      </span>
                      <span style={{ paddingLeft: "" }}>
                        {" "}
                        <img
                          src="./icon/close-modal.svg"
                          onClick={() => {
                            openRightSidebar = false;
                            handleClose();
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </span>
                    {Authors.map((author, i) =>
                      i > 0 ? (
                        <div
                          className="other-authors"
                          style={{
                            display: "flex",
                            padding: "5px",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            borderRadius: "4px",
                            width: "261px",
                            borderTopLeftRadius: "0",
                            borderTopRightRadius: "0",
                            height: "35px",
                            padding: "0px 0px 0px 15px",
                            alignItems: "center",
                            gap: "7px",
                            background:
                              "var(--Neutral-Background-16-Rest, #242424)",
                          }}
                          key={i}
                        >
                          <a
                            className=""
                            href={author.Link}
                            target="_blank"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "7px",
                            }}
                          >
                            {" "}
                            <img
                              className="not-hovered"
                              src="./icon/author-popover.svg"
                              alt=""
                            />
                            <img
                              className="hovered"
                              src="./icon/author-hovered-popover.svg"
                              alt=""
                            />
                            <span style={{ color: "white" }}>
                              {author.Name}
                            </span>
                          </a>
                        </div>
                      ) : null
                    )}
                  </Popover>
                </>
              ) : null}
            </p>
            <span>{Summary}</span>
          </div>
        </div>

        <div className="footer">
          <div className="footer-pills">
            {Products.map((item) => (
              <div className="">{item}</div>
            ))}
          </div>

          <div className="footer-btns">
            <a
              style={{
                border: "1px solid #50e6ff",
                borderRadius: "4px",
                flex: "1",
                background: "none",
                display: "flex",
                padding: "6px 35px",
                justifyContent: "center",
                alignItems: "center",
                color: "#50e6ff",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500 !important",
                lineHeight: "24px",
                letterSpacing: "0.42px",
              }}
            >
              <img src="./images/drops/dropcard/download-footer.svg" alt="" />
              Download
            </a>

            <button className="btn-share">
              <img src="./images/drops/dropcard/footer-share.svg" alt="" />
              Share
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DropCard.defaultProps = {
  cardData: {
    Title: "Title",
    Summary: "Card Description",
    Authors: [{ Name: "Author" }],
    Products: ["Tag", "Tag", "Tag", "Tag"],
    Source: "",
  },
};

export default DropCard;
