import React, { useEffect, useRef, useState } from "react";
import "./App.css";

// routing
import { Routes, Route, useLocation } from "react-router-dom";

// ui
import NavBar from "./components/navigation/NavBar";
import NavBarLower from "./components/navigation/NavBarLower";
import MenuDrawer from "./components/navigation/menu/MenuDrawer";
import SideMenu from "./components/navigation/sidemenu/SideMenu";
import SideMenuOpen from "./components/navigation/sidemenu/SideMenuOpen";
import SideMenuClose from "./components/navigation/sidemenu/SideMenuClose";
import SearchDrawer from "./components/navigation/searchbar/SearchDrawer";

// home page
import Home from "./components/pages/Home";

// adaptive cloud
import AdaptiveCloud from "./components/pages/adaptive-cloud/AdaptiveCloud";

// getting started pages
import JumpstartOverview from "./components/pages/getting-started/JumpstartOverview";
import JumpstartScenarios from "./components/pages/getting-started/JumpstartScenarios";
import JumpstartAgora from "./components/pages/getting-started/JumpstartAgora";
import JumpstartArcBox from "./components/pages/getting-started/JumpstartArcBox";
import JumpstartHCIBox from "./components/pages/getting-started/JumpstartHCIBox";
import About from "./components/pages/getting-started/About";
import Mission from "./components/pages/getting-started/Mission";
import ReleaseNotes from "./components/pages/getting-started/ReleaseNotes";
import AboutFaq from "./components/pages/getting-started/AboutFaq";

// Community pages
import Community from "./components/pages/community/Community";
import AllBlogs from "./components/pages/community/AllBlogs";
import ArcJumpstartBlogs from "./components/pages/community/ArcJumpstartBlogs";
import AdaptiveCloudBlogs from "./components/pages/community/AdaptiveCloudBlogs";
import Videos from "./components/pages/community/Videos";
import Contribute from "./components/pages/community/Contribute";
import Guidelines from "./components/pages/community/Guidelines";

// Resources pages
import JumpstartAssets from "./components/pages/resources/JumpstartAssets";
import Training from "./components/pages/resources/Training";
import Documentation from "./components/pages/resources/Documentation";

// markdown pages
import MarkdownPage from "./MarkdownPage";

// data
import menuDrawerData from "./data/menu-drawer.json";
import sideMenuData from "./data/side-menu.json";
import footerData from "./data/footer.json";
import blogData from "./data/blogs.json";
import cardData from "./data/cards.json";
import searchesData from "./data/searches.json";
import JumpStartDrops from "./components/pages/drops/jumpStartDrops";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { b2cPolicies } from "./auth/authConfig";
import useFetchWithMsal from "./hooks/useFetchWithMsal";
import { apiRequest } from "./auth/authConfig";
import DropCreation from "./components/pages/drop-creation/DropCreation";
import dropCard from "./data/dropsCards.json";
import DropDetails from "./components/pages/drops/dropDetails";
import MdFilePreviewer from "./components/mdFilePreviewer";
import NotFoundError from "./components/pages/NotFoundError/notFound";
import InternalServerError from "./components/pages/InternalServerError/internalServerError";
import { useAnalytics } from "./hooks/useAnalytics";

function App() {
  const { instance } = useMsal();
  useAnalytics();
  // const {data}=useFetchWithMsal()
  // console.log(data)

  // instance.addEventCallback((event) => { if (event.eventType === EventType.SSO_SILENT_FAILURE && event.error?.errorCode === 'monitor_window_timeout') { instance.acquireTokenRedirect({ ...apiRequest, }); } });
  // useEffect(() => {
  //     const callbackId = instance.addEventCallback((event) => {
  //         if (
  //             (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
  //             event.payload.account
  //         ) {
  //             /**
  //              * For the purpose of setting an active account for UI update, we want to consider only the auth
  //              * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
  //              * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
  //              * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
  //              */
  //             if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
  //                 // retrieve the account from initial sing-in to the app
  //                 const originalSignInAccount = instance
  //                     .getAllAccounts()
  //                     .find(
  //                         (account) =>
  //                             account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
  //                             account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
  //                             account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
  //                     );

  //                 let signUpSignInFlowRequest = {
  //                     authority: b2cPolicies.authorities.signUpSignIn.authority,
  //                     account: originalSignInAccount,
  //                 };

  //                 // silently login again with the signUpSignIn policy
  //                 instance.ssoSilent(signUpSignInFlowRequest);
  //             }

  //             /**
  //              * Below we are checking if the user is returning from the reset password flow.
  //              * If so, we will ask the user to reauthenticate with their new password.
  //              * If you do not want this behavior and prefer your users to stay signed in instead,
  //              * you can replace the code below with the same pattern used for handling the return from
  //              * profile edit flow
  //              */

  //         }

  //         if (event.eventType === EventType.LOGIN_FAILURE) {
  //             // Check for forgot password error
  //             // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
  //             if (event.error ) {
  //                 // const resetPasswordRequest = {
  //                 //     authority: b2cPolicies.authorities.forgotPassword.authority,
  //                 //     scopes: [],
  //                 // };
  //                 // instance.loginRedirect(resetPasswordRequest);
  //                 console.log(event.error)
  //             }
  //         }
  //     });

  //     return () => {
  //         if (callbackId) {
  //             instance.removeEventCallback(callbackId);
  //         }
  //     };
  //     // eslint-disable-next-line
  // }, [instance]);

  // data
  const [pathNode, setPathNode] = useState(sideMenuData);
  const [currentPathNode, setCurrentPathNode] = useState({});
  const [dynamicRoutes, setDynamicRoutes] = useState([]);

  const [menuItems, setMenuItems] = useState(menuDrawerData);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const [blogs, setBlogs] = useState(blogData);
  const [cards, setCards] = useState(cardData);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [sections, setSections] = useState([]);

  const pageRef = useRef(null);
  const [pathname, setPathname] = useState("");

  const location = useLocation();

  const findNode = (node, path) => {
    if (node.path.replace("\\/g", "/") === path.replace("\\/g", "/")) {
      return node;
    } else if (node.children) {
      let result = null;
      for (let i = 0; result == null && i < node.children.length; i++) {
        result = findNode(node.children[i], path);
      }
      return result;
    }
    return null;
  };

  function findNodeByTitle(node, path) {
    if (node.Title === path) {
      return node.Items && node.Items.length > 0 ? node : null;
    }

    if (node.Items && node.Items.length > 0) {
      for (let item of node.Items) {
        const result = findNodeByTitle(item, path);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  function findNodeInTree(tree, path) {
    for (let node of tree) {
      const result = findNodeByTitle(node, path);
      if (result) {
        return result;
      }
    }
    return null;
  }

  const searchMenuDrawerNode = (node, searchTerm) => {
    let results = [];
    searchTerm = searchTerm.toLowerCase();

    if (node.Title && node.Title.toLowerCase().includes(searchTerm)) {
      results.push(node);
    }

    if (node.Items && node.Items.length > 0) {
      for (let item of node.Items) {
        results = results.concat(searchMenuDrawerNode(item, searchTerm));
      }
    }

    return results;
  };

  const searchMenuDrawerTree = (tree, searchTerm) => {
    let results = [];

    for (let node of tree) {
      results = results.concat(searchMenuDrawerNode(node, searchTerm));
    }

    return results;
  };

  const searchSideMenuNode = (node, searchTerm) => {
    let results = [];
    searchTerm = searchTerm.toLowerCase();

    if (
      (node.frontMatter &&
        node.frontMatter.title &&
        node.frontMatter.title.toLowerCase().includes(searchTerm)) ||
      (node.frontMatter &&
        node.frontMatter.linkTitle &&
        node.frontMatter.linkTitle.toLowerCase().includes(searchTerm))
    ) {
      results.push(node);
    }

    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        results = results.concat(searchSideMenuNode(child, searchTerm));
      }
    }

    return results;
  };

  const searchSideMenuTree = (tree, searchTerm) => {
    return searchSideMenuNode(tree, searchTerm);
  };

  const parseNodeTitle = (node) => {
    if (node.frontMatter) {
      if (node.frontMatter.linkTitle) {
        return node.frontMatter.linkTitle;
      } else if (node.frontMatter.title) {
        return node.frontMatter.title;
      }
    }
    return node.path;
  };
  //Sort Node Tree
  const sortNodeTree = (node) => {
    if (node.children) {
      node.children.sort((a, b) => {
        const aWeight =
          a.frontMatter && a.frontMatter.weight ? a.frontMatter.weight : 0;
        const bWeight =
          b.frontMatter && b.frontMatter.weight ? b.frontMatter.weight : 0;
        if (aWeight < bWeight) {
          return -1;
        } else if (aWeight > bWeight) {
          return 1;
        } else {
          return 0;
        }
      });
      node.children.forEach((child) => {
        sortNodeTree(child);
      });
    }
  };

  const extractRoutes = (node, arr = []) => {
    arr.push({
      path: node.path.replace(/\\/g, "/"),
      title: parseNodeTitle(node),
    });

    if (node.hasOwnProperty("children") && node.children.length > 0) {
      node.children.forEach((child) => extractRoutes(child, arr));
    }
    return arr;
  };

  const findBreadcrumbs = (node, path) => {
    if (node.path.replace(/\\/g, "/") === path.replace(/\\/g, "/")) {
      // If the current node matches the path, return a breadcrumb for this node.
      return [
        {
          path: node.path,
          title:
            node.frontMatter?.linkTitle || node.frontMatter?.title || node.name,
        },
      ];
    } else if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const breadcrumbsFromChild = findBreadcrumbs(node.children[i], path);
        if (breadcrumbsFromChild.length) {
          // If the path was found deeper in the tree, prepend the current breadcrumb and return.
          return [
            {
              path: node.path,
              title:
                node.frontMatter?.linkTitle ||
                node.frontMatter?.title ||
                node.name,
            },
            ...breadcrumbsFromChild,
          ];
        }
      }
    }
    return []; // Return an empty array if the path wasn't found under the current node.
  };

  const updateSections = () => {
    if (pageRef.current) {
      const elementsWithId = pageRef.current.querySelectorAll("h2[id]");
      const discoveredSections = Array.from(elementsWithId).map((el) => ({
        id: el.id,
        name: el.innerText,
        href: `#${el.id}`,
      }));
      setSections(discoveredSections);
    }
  };

  useEffect(() => {
    sortNodeTree(pathNode);
    setCurrentPathNode({
      name: "docs",
      type: "directory",
      path: "",
      children: [
        findNode(pathNode, "azure_arc_jumpstart"),
        findNode(pathNode, "azure_jumpstart_ag"),
        findNode(pathNode, "azure_jumpstart_arcbox"),
        findNode(pathNode, "azure_jumpstart_hcibox"),
      ],
      frontMatter: {
        title: "docs",
      },
    });
    setDynamicRoutes(extractRoutes(pathNode));
  }, []);

  useEffect(() => {
    setPathname(location.pathname);
    // delay to allow page to render
    setTimeout(() => {
      if (location.hash) {
        const sectionElement = document.getElementById(
          location.hash.substring(1)
        );
        if (sectionElement) {
          const topPosition =
            sectionElement.getBoundingClientRect().top +
            window.pageYOffset -
            150; // Adjust 150 if needed
          window.scrollTo({
            top: topPosition,
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      } else {
        window.scrollTo(0, 0);
      }

      if (
        location.pathname === "/" ||
        location.pathname === "/404" ||
        location.pathname === "/500" ||
        location.pathname === "/adaptive_cloud" ||
        location.pathname === "/jumpstart_overview" ||
        location.pathname === "/azure_arc_jumpstart" ||
        location.pathname === "/azure_jumpstart_drops" ||
        location.pathname === "/azure_jumpstart_ag" ||
        location.pathname === "/azure_jumpstart_arcbox" ||
        location.pathname === "/azure_jumpstart_hcibox" ||
        location.pathname === "/about" ||
        location.pathname === "/mission" ||
        location.pathname === "/release_notes" ||
        location.pathname === "/faq" ||
        location.pathname === "/FAQ" ||
        location.pathname === "/community" ||
        location.pathname === "/contribution_guidelines" ||
        location.pathname === "/drops_contribution_guidelines" ||
        location.pathname === "/blogs" ||
        location.pathname === "/arc_jumpstart_blogs" ||
        location.pathname === "/adaptive_cloud_blogs" ||
        location.pathname === "/videos" ||
        location.pathname === "/contribute" ||
        location.pathname === "/jumpstart_assets" ||
        location.pathname === "/training" ||
        location.pathname === "/documentation" ||
        location.pathname === "/drop-data-preview" ||
        location.pathname === "/azure_jumpstart_drops_creation" ||
        dropCard.some(
          (cardData) =>
            location.pathname ===
            `/${cardData.Title.toLowerCase().replace(/\s/g, "_")}`
        )
      ) {
        setIsSplashPage(true);
        setIsSideMenuVisible(false);
      } else {
        setIsSplashPage(true);
        setIsSideMenuVisible(false);
      }

      const breadcrumbsFromTree = findBreadcrumbs(
        pathNode,
        location.pathname.replace(/^\/|\\/g, "")
      );
      // I don't want the home page to show up in the breadcrumbs
      if (breadcrumbsFromTree.length > 1) {
        breadcrumbsFromTree.shift();
      }
      setBreadcrumbs(breadcrumbsFromTree);
    }, 500);
  }, [location]);

  // ui
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isMenuDrawerVisible, setIsMenuDrawerVisible] = useState(false);
  const [queHideMenuDrawer, setQueHideMenuDrawer] = useState(false);
  const [isSearchDrawerVisible, setIsSearchDrawerVisible] = useState(false);
  const [queHideSearchDrawer, setQueHideSearchDrawer] = useState(false);
  const [searchResults, setSearchResults] = useState(searchesData);

  const menuDrawerRef = useRef(null);
  let menuDrawerTimerout = null;
  const searchDrawerRef = useRef(null);
  let searchDrawerTimerout = null;

  const handleMouseEnterNavbar = () => {
    setQueHideMenuDrawer(false);
    if (menuDrawerTimerout) {
      clearTimeout(menuDrawerTimerout);
      menuDrawerTimerout = null;
    }
    setQueHideSearchDrawer(false);
    if (searchDrawerTimerout) {
      clearTimeout(searchDrawerTimerout);
      searchDrawerTimerout = null;
    }
  };

  const handleMouseEnterMenuDrawer = () => {
    setQueHideMenuDrawer(false);
    if (menuDrawerTimerout) {
      clearTimeout(menuDrawerTimerout);
      menuDrawerTimerout = null;
    }
  };

  const handleMouseLeaveMenuDrawer = () => {
    setQueHideMenuDrawer(true);
  };

  const handleClickSearchDrawer = () => {
    setIsSearchDrawerVisible(false);
  };

  const handleMouseEnterSearchDrawer = () => {
    setQueHideSearchDrawer(false);
    if (searchDrawerTimerout) {
      clearTimeout(searchDrawerTimerout);
      searchDrawerTimerout = null;
    }
  };

  const handleMouseLeaveSearchDrawer = () => {
    setQueHideSearchDrawer(true);
  };

  const toggleMenuDrawer = (menuItem) => {
    if (menuItem) {
      setSelectedMenuItem(menuItem);
      setIsMenuDrawerVisible(menuItem ? true : false);
      setIsSearchDrawerVisible(false);
    } else {
      setQueHideMenuDrawer(true);
    }
  };

  useEffect(() => {
    if (queHideMenuDrawer) {
      menuDrawerTimerout = setTimeout(() => {
        if (queHideMenuDrawer) {
          setQueHideMenuDrawer(false);
          setIsMenuDrawerVisible(false);
          setSelectedMenuItem(null);
        }
      }, 500);
    }
    return () => {
      if (menuDrawerTimerout) {
        clearTimeout(menuDrawerTimerout);
        menuDrawerTimerout = null;
      }
    };
  }, [queHideMenuDrawer]);

  useEffect(() => {
    if (queHideSearchDrawer) {
      searchDrawerTimerout = setTimeout(() => {
        if (queHideSearchDrawer) {
          setQueHideSearchDrawer(false);
          setIsSearchDrawerVisible(false);
        }
      }, 500);
    }
    return () => {
      if (searchDrawerTimerout) {
        clearTimeout(searchDrawerTimerout);
        searchDrawerTimerout = null;
      }
    };
  }, [queHideSearchDrawer]);

  const toggleSearchDrawer = () => {
    setSelectedMenuItem(null);
    if (isSearchDrawerVisible) {
      setQueHideSearchDrawer(true);
    } else {
      setIsMenuDrawerVisible(false);
      setSearchResults(searchesData);
      setIsSearchDrawerVisible(!isSearchDrawerVisible);
    }
  };

  const onSearch = (searchTerm) => {
    if (searchTerm.trim() === "") {
      setSearchResults(searchesData);
    } else {
      const menuDrawerResults = searchMenuDrawerTree(
        menuDrawerData,
        searchTerm
      );
      const sideMenuResults = searchSideMenuTree(sideMenuData, searchTerm);
      // map menu drawer results and side menu results to { title, href }
      const mappedMenuDrawerResults = menuDrawerResults.map((result) => ({
        title: result.Title,
        href: result.Link,
      }));
      const mappedSideMenuResults = sideMenuResults.map((result) => ({
        title:
          result.frontMatter?.linkTitle ||
          result.frontMatter?.title ||
          result.name,
        href: result.path,
      }));
      // combine results
      const combinedResults = mappedMenuDrawerResults.concat(
        mappedSideMenuResults
      );
      // set results
      setSearchResults(combinedResults.slice(0, 6));
    }
  };

  const [isSplashPage, setIsSplashPage] = useState(true);

  return (
    <>
      <div className="app-nav-bar" onMouseEnter={handleMouseEnterNavbar}>
        <NavBar
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={toggleMenuDrawer}
          isSearchDrawerVisible={isSearchDrawerVisible}
          toggleSearchDrawer={toggleSearchDrawer}
          isMenuDrawerVisible={isMenuDrawerVisible}
          setQueHideMenuDrawer={setQueHideMenuDrawer}
          handleClickSearchDrawer={handleClickSearchDrawer}
          onSearch={onSearch}
          searchResults={searchResults}
          setQueHideSearchDrawer={setQueHideSearchDrawer}
        />
      </div>

      {!isSplashPage && !isMenuDrawerVisible && (
        <>
          <div
            className="app-nav-bar-lower-border-container focus-on-tab"
            style={{ marginTop: 30 }}
          >
            <div className="app-nav-bar-lower-border"></div>
          </div>
          <div className="app-nav-bar-lower">
            <NavBarLower breadcrumbs={breadcrumbs} sections={sections} />
          </div>
        </>
      )}

      <div className={`app-side-menu ${isSideMenuVisible ? "" : "hidden"}`}>
        <SideMenu pathNode={currentPathNode} pathname={pathname} />
        {isSideMenuVisible && (
          <span className="close-button">
            <SideMenuClose onClick={() => setIsSideMenuVisible(false)} />
          </span>
        )}
      </div>
      {!isSideMenuVisible && !isSplashPage && (
        <span className="open-button">
          <SideMenuOpen onClick={() => setIsSideMenuVisible(true)} />
        </span>
      )}
      <div
        ref={pageRef}
        className={`app-page-content ${
          isSideMenuVisible ? "menu-visible" : ""
        }  ${isSplashPage ? "splash-page" : ""}`}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Home blogs={blogs} cards={cards} footerData={footerData} />
            }
          />
          <Route
            path="/404"
            element={<NotFoundError footerData={footerData} />}
          />
          <Route
            path="/500"
            element={<InternalServerError footerData={footerData} />}
          />
          {dropCard.map((cardData) => (
            <Route
              key={cardData.Title}
              path={`/${cardData.Title.toLowerCase().replace(/\s/g, "_")}`}
              element={<DropDetails cardData={cardData} />}
            />
          ))}
          <Route
            path="adaptive_cloud"
            element={
              <AdaptiveCloud
                blogs={blogs.filter(
                  (blog) => blog.BlogType === "Adaptive Cloud"
                )}
                footerData={footerData}
              />
            }
          />
          {/* getting started */}
          <Route
            path="/jumpstart_overview"
            element={
              <JumpstartOverview
                node={findNodeInTree(menuDrawerData, "Jumpstart Overview")}
                cards={cards}
                footerData={footerData}
              />
            }
          />
          <Route path="/drop-data-preview" element={<MdFilePreviewer />} />
          <Route
            path="/azure_arc_jumpstart"
            element={
              <JumpstartScenarios
                node={findNodeInTree(menuDrawerData, "Jumpstart Scenarios")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="azure_jumpstart_ag"
            element={
              <JumpstartAgora
                node={findNodeInTree(menuDrawerData, "Jumpstart Agora")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="/azure_jumpstart_arcbox"
            element={
              <JumpstartArcBox
                node={findNodeInTree(menuDrawerData, "Jumpstart ArcBox")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="/azure_jumpstart_drops"
            element={
              <JumpStartDrops
                node={findNodeInTree(menuDrawerData, "Jumpstart Drops")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="/azure_jumpstart_drops_creation"
            element={
              <DropCreation
                node={findNodeInTree(menuDrawerData, "Jumpstart Drops")}
              />
            }
          />
          <Route
            path="/azure_jumpstart_hcibox"
            element={
              <JumpstartHCIBox
                node={findNodeInTree(menuDrawerData, "Jumpstart HCIBox")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="/about"
            element={
              <About
                node={findNodeInTree(menuDrawerData, "About")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="mission"
            element={
              <Mission
                node={findNodeInTree(
                  menuDrawerData,
                  "Our mission and principles"
                )}
                footerData={footerData}
              >
                <MarkdownPage
                  node={pathNode}
                  path="mission"
                  updateSections={updateSections}
                />
              </Mission>
            }
          />
          <Route
            path="release_notes"
            element={
              <ReleaseNotes
                node={findNodeInTree(
                  menuDrawerData,
                  "Arc Jumpstart release notes"
                )}
                footerData={footerData}
              >
                <MarkdownPage
                  node={pathNode}
                  path="release_notes"
                  updateSections={updateSections}
                />
              </ReleaseNotes>
            }
          />
          <Route
            path="FAQ"
            element={
              <AboutFaq
                node={findNodeInTree(menuDrawerData, "FAQ")}
                footerData={footerData}
              >
                <MarkdownPage
                  node={pathNode}
                  path="FAQ"
                  updateSections={updateSections}
                />
              </AboutFaq>
            }
          />
          {/* community */}
          <Route
            path="community"
            element={
              <Community
                node={findNodeInTree(menuDrawerData, "Community")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="blogs"
            element={<AllBlogs blogs={blogs} footerData={footerData} />}
          />
          <Route
            path="arc_jumpstart_blogs"
            element={
              <ArcJumpstartBlogs
                blogs={blogs.filter(
                  (blog) => blog.BlogType === "Arc Jumpstart"
                )}
                footerData={footerData}
              />
            }
          />
          <Route
            path="adaptive_cloud_blogs"
            element={
              <AdaptiveCloudBlogs
                blogs={blogs.filter(
                  (blog) => blog.BlogType === "Adaptive Cloud"
                )}
                footerData={footerData}
              />
            }
          />
          <Route
            path="videos"
            element={
              <Videos
                node={findNodeInTree(menuDrawerData, "Videos")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="contribute"
            element={
              <Contribute
                node={findNodeInTree(menuDrawerData, "Contribute")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="contribution_guidelines"
            element={
              <Guidelines
                node={findNodeInTree(menuDrawerData, "Write-up guidelines")}
                footerData={footerData}
              >
                <MarkdownPage
                  node={pathNode}
                  path="contribution_guidelines"
                  updateSections={updateSections}
                />
              </Guidelines>
            }
          />
          <Route
            path="drops_contribution_guidelines"
            element={
              <Guidelines
                node={findNodeInTree(menuDrawerData, "Write-up guidelines")}
                footerData={footerData}
              >
                <MarkdownPage
                  node={pathNode}
                  path="drops_contribution_guidelines"
                  updateSections={updateSections}
                />
              </Guidelines>
            }
          />
          {/* resources */}
          <Route
            path="jumpstart_assets"
            element={
              <JumpstartAssets
                node={findNodeInTree(menuDrawerData, "Jumpstart assets")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="training"
            element={
              <Training
                node={findNodeInTree(menuDrawerData, "Training")}
                footerData={footerData}
              />
            }
          />
          <Route
            path="documentation"
            element={
              <Documentation
                node={findNodeInTree(menuDrawerData, "Documentation")}
                footerData={footerData}
              />
            }
          />
          {/* markdown */}
          {dynamicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <MarkdownPage
                  node={pathNode}
                  path={route.path}
                  updateSections={updateSections}
                />
              }
            />
          ))}
        </Routes>
      </div>
    </>
  );
}

export default App;
