import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';

export const useAnalytics = () => {
  const instrumentationKey = process.env.ADOBE_ANALYTICS_KEY || "";
  const analytics = new ApplicationInsights();
  var config = {
    instrumentationKey: instrumentationKey,
    channelConfiguration: {
      eventsLimitInMem: 5000,
    },
    propertyConfiguration: {
      env: "PROD",
    },
    webAnalyticsConfiguration: {
      autoCapture: {
        scroll: true,
        pageView: true,
        onLoad: true,
        onUnload: true,
        click: true,
        resize: true,
        jsError: true
      }
    }
  };

  //Initialize SDK
  analytics.initialize(config, []);
};