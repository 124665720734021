import { useEffect, useMemo, useState } from "react";
import Footer from "../../navigation/footer/Footer";
import "./DropCreation.css";
import DropCard from "../../dropCard/DropCard";
import Drpofilters from "../../../data/dropsFilter.json";
import { Link, json, useNavigate } from "react-router-dom";
import SidePanel from "./SidePanel";
import CardSchema from "./CardSchema";
import Modal from "react-modal";
import { useSidePanelContext } from "../../../context/SidepanelProvider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ToolTip from "../../toolTip/toolTip";
import { downloadJSON } from "../../../utils/helper";

const DropCreation = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState(Drpofilters);
  const [showAccordian, setShowAccordian] = useState(
    Array.from(Array(4), (_, i) => false)
  );
  const [accordianStatus, setAccordianStatus] = useState(
    Array.from(Array(4), (_, i) => "Not started")
  );
  const [sidePanelAccordianStatus, setSidePanelAccordianStatus] = useState(
    Array.from(Array(5), (_, i) => "Not started")
  );

  const [expandAll, setExpandAll] = useState(false);
  const [creating, setCreating] = useState(true);
  const [cardCreationInProgress, setCardCreationInProgress] = useState(false);
  let [cardData, setCardData] = useState({
    Title: "Title",
    Summary: "Card description",
    Type: "script_automation",
    Authors: [{ Name: "Author", Link: "" }],
    Products: ["Tag", "Tag", "Tag", "Tag"],
    Source: "",
    ProgrammingLanguage: [],
    Difficulty: "",
    Topics: [],
    Type: "",
  });
  const [cardDataValidation, setCardDataValidation] = useState(
    Array.from(Array(3), (_, i) => "")
  );
  const [authorLinkValidation, setAuthorLinkValidation] = useState([]);
  const [jsonUploadingStatus, setJsonUploadingStatus] = useState({
    fileName: "",
    status: "",
  });
  const [gitHubUrlValidatity, setGitHubUrlValidity] = useState({
    status: "",
    message: "",
    readOnly: false,
  });
  const [personalGitHubUrlValidatity, setPersonalGitHubUrlValidity] = useState({
    status: "",
    message: "",
  });
  const [authorInput, setAuthorInput] = useState();
  const [mdText, setMdText] = useState();
  const [uploadOption, setUploadOption] = useState(true);
  const [exsitingJsonSchema, setExistingJsonSchema] = useState();
  const [creatingDrop, setCreatingDrop] = useState(true);
  const [showFAQ, setShowFAQ] = useState(false);
  const [completeCreation, setCompleteCreation] = useState(false);
  const [sidePanelCreationStatusCompletd, setSidePanelCreationStatusCompleted] =
    useState(false);
  const [showAfterCreation, setShowAfterCreation] = useState(false);
  const [dropCreationStatus, setDropCreationStatus] = useState("Not started");
  const [sidePanelDropCreation, setSidePanelDropCreation] =
    useState("Not started");
  const [coverImage, setCoverImage] = useState();
  const [coverImageName, setCoverImageName] = useState();
  const [resourceImage, setResourceImage] = useState();
  const [resourceImageName, setResourceImageName] = useState();
  const [artifactImage, setArtifactImage] = useState();
  const [artifactImageName, setArtifactImageName] = useState();
  const [createdPullRequest, setCreatedPullRequest] = useState();
  const [githubPrefrence, setGithubPrefrence] = useState("jumpstart");
  const [progress, setProgress] = useState();
  const [progressCalculate, setProgressCalculate] = useState(0);
  const [sidePaneluploadOption, setSidePanelUploadOption] = useState(true);
  const [sidePanelCreating, setSidePanelCreating] = useState(true);
  const [usingExtarnalDropSchema, setUsingExternalDropSchema] = useState(false);
  const [isMdFileUploaded, setIsMdFileUploaded] = useState(false);
  const [tagFilter, setTagFilter] = useState({
    Product: "",
    Language: "",
    Topics: "",
    Type: "",
    Difficulty: "",
  });
  const [addNewTag, setAddNewTag] = useState({
    Products: [],
    Language: [],
    Topics: [],
  });
  const [mdLink, setMdLink] = useState();
  const { dispatch } = useSidePanelContext();

  useEffect(() => {
    dispatch({
      type: "RESET_SIDEPANEL_INPUTS",
    });
  }, []);

  const handleOnCloseFAQ = () => {
    setShowFAQ(false);
  };
  const [seconds, setSeconds] = useState(20);
  useEffect(() => {
    let interval;
    if (completeCreation && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setProgressCalculate((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (seconds === 0) {
      setCompleteCreation(false);
      setShowAfterCreation(true);
    }

    return () => clearInterval(interval);
  }, [completeCreation, seconds]);
  const countDown = () => {
    setSeconds(20);
    setCompleteCreation(true);
  };
  console.log(tagFilter["Product"]);
  const handleCreateDrop = () => {
    if (githubPrefrence === "personal" && !sidePanelCreationStatusCompletd)
      setPersonalGitHubUrlValidity({
        status: "error",
        message: "Enter valid URL",
      });
    if (
      (accordianStatus.includes("Not started") &&
        dropCreationStatus !== "Completed") ||
      (accordianStatus.includes("In progress") &&
        dropCreationStatus !== "Completed")
    )
      setDropCreationStatus("error");
    else if (
      (sidePanelAccordianStatus.includes("Not started") &&
        sidePanelCreationStatusCompletd !== true) ||
      (sidePanelAccordianStatus.includes("In progress") &&
        sidePanelCreationStatusCompletd !== true)
    )
      setSidePanelDropCreation("error");
    else {
      countDown();
      fetch(`${process.env.REACT_APP_API_URL}/createDrop`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: {
            ...cardData,
            Source:
              githubPrefrence === "jumpstart"
                ? `https://github.com/${
                    process.env.REACT_APP_GITHUB_ORG
                  }/arc_jumpstart_drops/tree/main/${
                    cardData.Type
                  }/${cardData.Title.replace(/\s+/g, "_").toLowerCase()}`
                : cardData.Source,
          },
          mdText: githubPrefrence === "jumpstart" && mdText,
          coverImage,
          coverImageName,
          resourceImage,
          resourceImageName,
          artifactImage,
          artifactImageName,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          return response.json();
        })
        .then((data) => {
          console.log("Response:", data);
          setProgress(100);
          setSeconds(2);
          if (data.data.url) {
            let url = data.data.url;
            url = url.replace("api.", "");
            url = url.replace("repos/", "");
            url = url.replace("pulls", "pull");
            setCreatedPullRequest(url);
          } else setCreatedPullRequest(null);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  };

  useMemo(() => {
    if (accordianStatus.every((val) => val === "Not started"))
      setDropCreationStatus("Not started");
    if (accordianStatus.some((val) => val === "In progress"))
      setDropCreationStatus("In progress");

    if (accordianStatus.every((val) => val === "Completed"))
      setDropCreationStatus("Completed");
  }, [accordianStatus]);

  console.log(sidePanelCreationStatusCompletd);

  useMemo(() => {
    if (sidePanelAccordianStatus.every((val) => val === "Not started"))
      setSidePanelDropCreation("Not started");
    if (sidePanelAccordianStatus.some((val) => val === "In progress"))
      setSidePanelDropCreation("In progress");

    if (sidePanelAccordianStatus.every((val) => val === "Completed"))
      setSidePanelDropCreation("Completed");

    if (sidePanelCreationStatusCompletd) setSidePanelDropCreation("Completed");
  }, [sidePanelAccordianStatus, sidePanelCreationStatusCompletd]);

  const mdLinkSetter = (Source) => {
    if (Source.includes("blob")) {
      const parts = Source.split("/blob");
      var newLink = parts[0] + parts[1];
    }
    if (Source.includes("tree")) {
      const parts = Source.split("/tree");
      var newLink = parts[0] + parts[1];
    }
    setMdLink(
      newLink
        ? `https://raw.githubusercontent.com${newLink.substring(18)}`
        : `https://raw.githubusercontent.com${Source.substring(18)}`
    );
  };
  useMemo(() => {
    if (usingExtarnalDropSchema) {
      if (mdLink?.includes("arc_jumpstart_drops") && mdLink?.includes("main"))
        var canaryLink = mdLink.replace(/\/main\//, "/canary/");
      else if (mdLink?.includes("arc_jumpstart_drops")) {
        // Find the index of "arc_jumpstart_drops/"
        const index = mdLink.indexOf("arc_jumpstart_drops/");

        if (index !== -1) {
          // Extract the first part of the URL until "arc_jumpstart_drops/"
          const firstPart = mdLink.substring(
            0,
            index + "arc_jumpstart_drops/".length
          );

          // Extract the second part of the URL after "arc_jumpstart_drops/"
          const secondPart = mdLink.substring(
            index + "arc_jumpstart_drops/".length
          );
          var canaryLink = firstPart + "canary/";
        }
      }
      if (mdLink) {
        const urls = [
          `${mdLink}/main/README.md`,
          `${mdLink}/master/README.md`,
          `${mdLink}/README.md`,
          `${mdLink}/index.md`,
          `${mdLink}/main/index.md`,
          `${mdLink}/master/index.md`,
          `${mdLink}/_index.md`,
          `${mdLink}/main/_index.md`,
          `${mdLink}/master/_index.md`,
          `${canaryLink && `${canaryLink}/_index.md`}`,
          `${canaryLink && `${canaryLink}/index.md`}`,
        ];

        const promises = urls.map((url) => fetch(url));

        Promise.all(promises)
          .then((responses) => {
            const results = responses.map((response) => response.text());
            return Promise.all(results);
          })
          .then((data) => {
            const mdData = data.find((text) => text.length > 15);
            setMdText(mdData);
          })
          .catch((error) => console.error(error));
      }
    }
  }, [usingExtarnalDropSchema]);

  function getFilterValues(filterName) {
    const filter = filters.find((f) => f.name === filterName);
    return filter ? filter.tags.map((tag) => tag.name) : [];
  }

  function validateJsonData(jsonData) {
    const results = [
      jsonData.Type ? getFilterValues("Type").includes(jsonData.Type) : false,
      jsonData.Difficulty
        ? getFilterValues("Difficulty").includes(jsonData.Difficulty)
        : false,
      jsonData.ProgrammingLanguage
        ? jsonData.ProgrammingLanguage.every((lang) =>
            getFilterValues("Language").includes(lang)
          )
        : false,
      jsonData.Products
        ? jsonData.Products.every((product) =>
            getFilterValues("Product").includes(product)
          )
        : false,
      jsonData.Topics
        ? jsonData.Topics.every((topic) =>
            getFilterValues("Topics").includes(topic)
          )
        : false,
    ];

    return results;
  }

  const handleJsonFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (event.target.files[0])
      setJsonUploadingStatus({
        fileName: event.target.files[0]?.name,
        status: event.target.files[0]?.name?.includes("json")
          ? "success"
          : "error",
        size: event.target.files[0]?.size,
        showProgress: true,
        message:
          !event.target.files[0]?.name?.includes("json") && "Invalid file",
      });

    reader.onload = (e) => {
      try {
        const parsedData = JSON.parse(e.target.result);

        const keys = Object.keys(parsedData);
        const requiredKeys = [
          "Title",
          "Source",
          "Summary",
          "Description",
          "Cover",
          "Type",
          "Authors",
          "Products",
          "ProgrammingLanguage",
          "Difficulty",
          "Topics",
          "LastModified",
          "CreatedDate",
        ];
        if (requiredKeys.every((key) => keys.includes(key))) {
          if (keys.length > 13)
            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "error",
                  showProgress: false,
                  message: "Extra keys are not allowed",
                }),
              1000
            );
          else {
            setDropCreationStatus("Completed");
            setSidePanelCreationStatusCompleted(true);
            setCardData({
              ...parsedData,
              Summary: parsedData.Summary.slice(0, 250),
              Title: parsedData.Title.slice(0, 100),
            });
            setUsingExternalDropSchema(true);
            mdLinkSetter(parsedData?.Source);

            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "success",
                  showProgress: false,
                }),
              1000
            );
          }
        } else {
          const key = requiredKeys.filter((key) => !keys.includes(key));

          setTimeout(
            () =>
              setJsonUploadingStatus({
                fileName: event.target.files[0]?.name,
                size: event.target.files[0]?.size,
                status: "error",
                showProgress: false,
                message: `${key} should be in JSON`,
              }),
            1000
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
    console.log(cardData);
    try {
      reader.readAsText(file);
    } catch {
      console.log("An error occured");
    }
  };
  const handleJsonFileDrop = (event) => {
    const file = event.dataTransfer.files[0];
    if (event.target.files[0])
      setJsonUploadingStatus({
        fileName: event.target.files[0]?.name,
        status: event.target.files[0]?.name?.includes("json")
          ? "success"
          : "error",
        size: event.target.files[0]?.size,
        showProgress: true,
        message:
          !event.target.files[0]?.name?.includes("json") && "Invalid file",
      });
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const parsedData = JSON.parse(e.target.result);
        const keys = Object.keys(parsedData);
        const requiredKeys = [
          "Title",
          "Source",
          "Summary",
          "Description",
          "Cover",
          "Type",
          "Authors",
          "Products",
          "ProgrammingLanguage",
          "Difficulty",
          "Topics",
          "LastModified",
          "CreatedDate",
        ];
        if (requiredKeys.every((key) => keys.includes(key))) {
          if (keys.length > 13)
            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "error",
                  showProgress: false,
                  message: "Extra keys are not allowed",
                }),
              1000
            );
          else if (keys[0].length > 100)
            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "error",
                  showProgress: false,
                  message: "Title should be less than 100 characters",
                }),
              1000
            );
          else {
            setDropCreationStatus("Completed");
            setSidePanelCreationStatusCompleted(true);
            setCardData({
              ...parsedData,
              Summary: parsedData.Summary.slice(0, 250),
              Title: parsedData.Title.slice(0, 100),
            });
            setUsingExternalDropSchema(true);
            mdLinkSetter(parsedData?.Source);

            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "success",
                  showProgress: false,
                }),
              1000
            );
          }
        } else {
          const key = requiredKeys.filter((key) => !keys.includes(key));

          setTimeout(
            () =>
              setJsonUploadingStatus({
                fileName: event.target.files[0]?.name,
                size: event.target.files[0]?.size,
                status: "error",
                showProgress: false,
                message: `${key} should be in JSON`,
              }),
            1000
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
    console.log(cardData);
    try {
      reader.readAsText(file);
    } catch {
      console.log("An error occured");
    }
  };

  const handleDropGithubUrl = async (event) => {
    if (event.target.value.includes(".json")) {
      if (event.target.value.includes("blob")) {
        const parts = event.target.value.split("/blob");
        var newLink = parts[0] + parts[1];
      }
      if (event.target.value.includes("tree")) {
        const parts = event.target.value.split("/tree");
        var newLink = parts[0] + parts[1];
      }

      const githubLink = newLink
        ? `https://raw.githubusercontent.com${newLink.substring(18)}`
        : `https://raw.githubusercontent.com${event.target.value.substring(
            18
          )}`;

      try {
        const response = await fetch(githubLink);
        const data = await response.json();
        const keys = Object.keys(data);
        const requiredKeys = [
          "Title",
          "Source",
          "Summary",
          "Description",
          "Cover",
          "Type",
          "Authors",
          "Products",
          "ProgrammingLanguage",
          "Difficulty",
          "Topics",
          "LastModified",
          "CreatedDate",
        ];
        if (requiredKeys.every((key) => keys.includes(key))) {
          if (keys.length > 13)
            setGitHubUrlValidity({
              status: "error",
              message: "Extra keys are not allowed",
              readOnly: false,
              input: event.target.value,
            });
          else {
            setDropCreationStatus("Completed");
            setCardData({
              ...data,
              Summary: data.Summary.slice(0, 250),
              Title: data.Title.slice(0, 100),
            });
            setUsingExternalDropSchema(true);
            setSidePanelCreationStatusCompleted(true);
            mdLinkSetter(data?.Source);
            setGitHubUrlValidity({
              url: event.target.value,
              status: "",
              message: "",
              readOnly: true,
              input: event.target.value,
            });
          }
        } else {
          const key = requiredKeys.filter((key) => !keys.includes(key));
          setGitHubUrlValidity({
            status: "error",
            message: `The ${key[0]} should include in JSON`,
            input: event.target.value,
          });
        }
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    } else
      setGitHubUrlValidity({
        status: "error",
        message: "The URL should be contain JSON",
        input: event.target.value,
      });
  };

  return (
    <div className="creation-wizard">
      <div className="creation-header">
        <div
          onClick={() => navigate("/azure_jumpstart_drops")}
          style={{ cursor: "pointer" }}
        >
          <img src="./icon/creation-back-arrow.svg" alt="" />
          <span>Create a Drop</span>
        </div>

        <div className="cta-btns mta-btns">
          <div
            className={
              dropCreationStatus === "Not started"
                ? "nta-btns"
                : dropCreationStatus === "In progress"
                ? "pta-btns"
                : dropCreationStatus === "error"
                ? "eta-btns"
                : "cota-btns"
            }
          >
            <input
              type="radio"
              id="createDrop"
              name="gender"
              value=""
              style={{ background: "none" }}
              checked={creatingDrop}
              onClick={() => setCreatingDrop(true)}
            />
            <label for="createDrop">Card details</label>
          </div>
          <div
            className={
              sidePanelDropCreation === "Not started"
                ? "nta-btns"
                : sidePanelDropCreation === "In progress"
                ? "pta-btns"
                : sidePanelDropCreation === "error"
                ? "eta-btns"
                : "cota-btns"
            }
          >
            <input
              type="radio"
              id="sidePanel"
              name="gender"
              value="F"
              checked={!creatingDrop}
              onClick={() => {
                if (
                  githubPrefrence === "personal" &&
                  !sidePanelCreationStatusCompletd
                ) {
                  setSidePanelDropCreation("error");
                  setPersonalGitHubUrlValidity({
                    status: "error",
                    message: "Enter valid URL",
                  });
                } else setCreatingDrop(false);
              }}
            />
            <label for="sidePanel">Side panel details</label>
          </div>
        </div>
        <button className="creation-btn" onClick={handleCreateDrop}>
          <img
            className="normal-drop-creation-icon"
            src="../icon/creation-drop-light.svg"
            alt=""
          />
          <img
            className="hovered-drop-creation-icon"
            src="../icon/creation-drop-light-hover.svg"
            alt=""
          />
          Create
        </button>
      </div>
      <div className="creation-drops">
        {creatingDrop ? (
          <>
            {!showFAQ ? (
              <div className="card-preview" id="card-preview" style={{}}>
                <p className="dropcard-heading" htmlFor="">
                  Card display
                  <ToolTip
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        This shows a preview of the the card display that will
                        be created when this drop is approved and published.
                        Information is shown based on what's provided in the
                        "card details" section.
                        <span>
                          Learn more in the{" "}
                          <a
                            style={{
                              fontSize: "var(--Size-200, 12px)",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "var(--Line-height-200, 16px)",
                              cursor: "pointer",
                            }}
                            href="/drops_contribution_guidelines"
                            target="_blank"
                          >
                            Drops contribution guidelines
                          </a>{" "}
                        </span>
                      </div>
                    }
                    height={100}
                    width={300}
                  >
                    <div className="info-icon">
                      <img
                        className="normal-info-icon"
                        src="./images/drops/sidebar/info-icon.svg"
                        alt=""
                      />

                      <img
                        className="hovered-info-icon"
                        src="./images/drops/sidebar/info-icon-hover.svg"
                        alt=""
                      />
                    </div>
                  </ToolTip>
                  <button className="wizard-preview">PREVIEW</button>
                </p>
                <DropCard cardData={cardData} />
              </div>
            ) : null}
            <div
              className="card-details"
              style={{
                marginLeft: showFAQ ? "0" : "20px",
                width: "60%",
                maxHeight: "1000px",
                overflow: "scroll",
              }}
            >
              <span>
                Card details
                <ToolTip
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      Complete this section according to this drop's JSON
                      schema. This information provided will be used for search,
                      filters, and the drop card.
                      <span>
                        Learn more in the{" "}
                        <a
                          style={{
                            fontSize: "var(--Size-200, 12px)",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "var(--Line-height-200, 16px)",
                            cursor: "pointer",
                          }}
                          href="/drops_contribution_guidelines"
                          target="_blank"
                        >
                          Drops contribution guidelines
                        </a>{" "}
                      </span>
                    </div>
                  }
                  height={80}
                  width={280}
                >
                  <div className="info-icon">
                    <img
                      className="normal-info-icon"
                      src="./images/drops/sidebar/info-icon.svg"
                      alt=""
                    />

                    <img
                      className="hovered-info-icon"
                      src="./images/drops/sidebar/info-icon-hover.svg"
                      alt=""
                    />
                  </div>
                </ToolTip>
                <button>
                  {" "}
                  <div className="wizard-edit">EDIT</div>
                </button>
              </span>
              <div>
                <img src="../icon/card-details-creation.svg" alt="" />
              </div>{" "}
              <div className="card-details-cta-btns">
                <div
                  className="outline-cta-btns"
                  style={{
                    outline:
                      githubPrefrence === "jumpstart"
                        ? "1px solid #50e6ff"
                        : "",
                  }}
                >
                  <input
                    type="radio"
                    id="jumpstart-repo"
                    name="jumpstart-repo"
                    value="step-by-step"
                    checked={githubPrefrence === "jumpstart"}
                    onClick={() => setGithubPrefrence("jumpstart")}
                  />
                  <label for="jumpstart-repo">
                    Create in Drops GitHub repository
                  </label>
                </div>
                <div
                  className="outline-cta-btns"
                  style={{
                    outline:
                      githubPrefrence === "personal"
                        ? "1px solid #50e6ff "
                        : "",
                    paddingRight: githubPrefrence === "personal" ? "18px" : "",
                  }}
                >
                  <input
                    type="radio"
                    id="personal-repo"
                    name="personal-repo"
                    value="upload"
                    checked={githubPrefrence === "personal"}
                    onClick={() => setGithubPrefrence("personal")}
                  />
                  <label for="personal-repo">
                    Create in personal GitHub repository
                  </label>
                </div>
              </div>
              {githubPrefrence === "personal" ? (
                <div
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <input
                    type="text"
                    className="github-input"
                    placeholder="Enter GitHub URL"
                    style={{
                      border:
                        personalGitHubUrlValidatity.status === "error" &&
                        "1px solid #f87c86",
                    }}
                    id="pre-req"
                    value={cardData.Source}
                    onChange={(e) => {
                      setCardData({ ...cardData, Source: e.target.value });
                      const gitLink = e.target.value.split("/");
                      if (gitLink.length === 5 && gitLink[2] === "github.com") {
                        setSidePanelCreationStatusCompleted(true);
                        setPersonalGitHubUrlValidity({
                          status: "",
                          message: "",
                        });
                      } else
                        setPersonalGitHubUrlValidity({
                          status: "error",
                          message: "The URL should be from GitHub",
                        });
                    }}
                    onBlur={(e) =>
                      !e.target.value.includes("http://") &&
                      !e.target.value.includes("https://")
                        ? setPersonalGitHubUrlValidity({
                            status: "error",
                            message:
                              "The URL is not valid. Make sure it starts with http:// or https://.",
                          })
                        : setPersonalGitHubUrlValidity({
                            status: "",
                            message: "",
                          })
                    }
                  />
                  {personalGitHubUrlValidatity.status === "error" && (
                    <div className="validation">
                      <img src="./icon/input-error.svg" />

                      <span className="error-message">
                        {personalGitHubUrlValidatity.message}
                      </span>
                    </div>
                  )}
                  {personalGitHubUrlValidatity.status !== "error" &&
                    cardData.Source && (
                      <div className="uploading-status">
                        <div className="uploading-status-body">
                          <div className="file-head">
                            <img src="./icon/link-logo.svg" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>Personal GitHub URL</span>
                              <span className="file-size">
                                {cardData.Source}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p>Open link</p>
                              <a
                                href={cardData.Source}
                                target="_blank"
                                style={{ marginTop: "5px" }}
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src="./icon/open-link.svg"
                                />
                              </a>
                            </div>
                            {}
                          </div>
                        </div>
                        {}
                      </div>
                    )}
                </div>
              ) : null}
              <div
                style={{
                  backgroundColor: "#2F3646",
                  color: "#B2CDFF",
                  paddingLeft: "10px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  padding: "14px 10px",
                  margin: "12px 0 10px 0",
                  borderRadius: "10px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  {" "}
                  <img src="./icon/note-info.svg" style={{}} />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      Note:
                    </span>{" "}
                    Creating a Drop in the Drops GitHub repository provides more
                    functionalities such as automatically creating folders and
                    being able to use our side panel creation experience.{" "}
                    <span style={{ color: "#B2CDFF" }}>
                      {" "}
                      If you want complete ownership,
                    </span>
                    <span style={{ color: "rgb(80, 230, 255)" }}>
                      {" "}
                      <Link to="/drops_contribution_guidelines" target="_blank">
                        create in personal GitHub repository.
                      </Link>
                    </span>
                  </span>
                </span>
              </div>
              <div className="card-details-cta-btns">
                <div
                  className="outline-cta-btns"
                  style={{ outline: creating ? "1px solid #50e6ff" : "" }}
                >
                  <input
                    type="radio"
                    id="step-by-step"
                    name="creation-option"
                    value="step-by-step"
                    checked={creating}
                    onClick={() => {
                      if (dropCreationStatus !== "Completed") {
                        setCreating(true);
                        setDropCreationStatus("Not started");
                      }
                    }}
                  />
                  <label for="step-by-step">
                    Create with step-by-step guidance
                  </label>
                </div>
                <div
                  className="outline-cta-btns"
                  style={{ outline: !creating ? "1px solid #50e6ff" : "" }}
                >
                  <input
                    type="radio"
                    id="upload"
                    name="creation-option"
                    value="upload"
                    checked={!creating}
                    onClick={() =>
                      dropCreationStatus !== "Completed" && setCreating(false)
                    }
                  />
                  <label for="upload">Create with existing JSON schema</label>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#2F3646",
                  color: "#B2CDFF",

                  paddingLeft: "10px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  padding: "14px 10px",
                  margin: "12px 0 10px 0",
                  borderRadius: "10px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  {" "}
                  <img src="./icon/note-info.svg" style={{}} />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "20px",
                      }}
                    >
                      Note:
                    </span>{" "}
                    {creating
                      ? "Complete the fields below and we will create a Drop using GitHub mechanisms. You will be able to make changes later in GitHub."
                      : " We will parse the information from the file or URL you provide and auto-populate the Drop for you. You will be able to make changes later in GitHub."}
                  </span>
                </span>
              </div>
              {!creating ? (
                <>
                  <div className="card-details-cta-btns">
                    <div
                      className="outline-cta-btns"
                      style={{
                        outline: uploadOption ? "1px solid #50e6ff" : "",
                      }}
                      onClick={() =>
                        dropCreationStatus !== "Completed" &&
                        setUploadOption(true)
                      }
                    >
                      <input
                        type="radio"
                        id="upload1"
                        name="uploading-option"
                        value="upload-a-file"
                        checked={uploadOption}
                      />
                      <label for="upload1">Upload file</label>
                    </div>
                    <div
                      className="outline-cta-btns"
                      style={{
                        outline: !uploadOption ? "1px solid #50e6ff" : "",
                      }}
                      onClick={() =>
                        dropCreationStatus !== "Completed" &&
                        setUploadOption(false)
                      }
                    >
                      <input
                        type="radio"
                        id="upload2"
                        name="uploading-option"
                        value="upload-a-url"
                        checked={!uploadOption}
                      />
                      <label for="upload2">Enter URL</label>
                    </div>
                  </div>
                  {uploadOption && (
                    <>
                      <div className="uploading-asset">
                        <div>
                          <img src="./icon/uploading-asset.svg" alt="" />
                        </div>
                        <span>Drag and drop or</span>
                        <input
                          type="file"
                          style={{
                            opacity: 0,
                            position: "absolute",
                            marginTop: "30px",
                            padding: "40px 200px 100px 200px",
                            width: "auto",
                            cursor: "pointer",
                          }}
                          readOnly={dropCreationStatus === "Completed"}
                          accept=".json"
                          onChange={handleJsonFileUpload}
                          onDrop={handleJsonFileDrop}
                        />
                        <button className="choose-file">
                          Choose file{" "}
                          <input type="file" id="myfile" name="myfile" />
                        </button>

                        <p>JSON</p>
                      </div>
                      {jsonUploadingStatus.fileName && (
                        <div
                          className="uploading-status"
                          style={{
                            border:
                              jsonUploadingStatus.status === "error" &&
                              "1px solid #f87c86",
                            marginTop: "10px",
                          }}
                        >
                          <div className="uploading-status-body">
                            <div className="file-head">
                              <img src="./icon/file.svg" />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{jsonUploadingStatus.fileName}</span>
                                <span className="file-size">
                                  {jsonUploadingStatus.size} KB
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p>Download</p>
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    downloadJSON(cardData, "drops")
                                  }
                                  src="./icon/download_status.svg"
                                />
                              </div>
                              <img
                                style={{ cursor: "pointer" }}
                                src="./icon/close-modal.svg"
                                width={15}
                                height={15}
                                onClick={() => {
                                  setDropCreationStatus("Not started");
                                  setSidePanelCreationStatusCompleted(false);
                                  setUsingExternalDropSchema(false);
                                  setCardData({
                                    Title: "Title",
                                    Summary: "Card description",
                                    Type: "script_automation",
                                    Authors: [{ Name: "Author", Link: "" }],
                                    Products: ["Tag", "Tag", "Tag", "Tag"],
                                    Source: "",
                                    ProgrammingLanguage: [],
                                    Difficulty: "",
                                    Topics: [],
                                    Type: "",
                                  });
                                  setJsonUploadingStatus({
                                    fileName: "",
                                    status: "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {jsonUploadingStatus.showProgress && (
                            <div class="upload-progress-bar"></div>
                          )}
                        </div>
                      )}
                      {jsonUploadingStatus.status === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            {jsonUploadingStatus.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {!uploadOption && (
                    <div className="uploading-git-url">
                      <input
                        className="accord2-inp"
                        type="text"
                        value={gitHubUrlValidatity?.input}
                        htmlFor="title2"
                        placeholder="Enter GitHub URL"
                        readOnly={gitHubUrlValidatity.readOnly}
                        style={{
                          marginTop: "15px",
                          border:
                            gitHubUrlValidatity.status === "error" &&
                            "1px solid #f87c86",
                        }}
                        onChange={handleDropGithubUrl}
                        onBlur={(e) => {
                          if (gitHubUrlValidatity.status !== "error") {
                            (!e.target.value.includes("http://") &&
                              !e.target.value.includes("https://")) ||
                            !e.target.value.includes(".json")
                              ? setGitHubUrlValidity({
                                  ...gitHubUrlValidatity,
                                  status: "error",
                                  message:
                                    "The URL is not valid. Make sure it starts with http:// or https://.",
                                  input: e.target.value,
                                })
                              : setGitHubUrlValidity({
                                  ...gitHubUrlValidatity,
                                  status: "",
                                  message: "",
                                  input: e.target.value,
                                });
                          }
                        }}
                      />
                      {gitHubUrlValidatity.status === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            {gitHubUrlValidatity.message}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {gitHubUrlValidatity.status !== "error" &&
                    gitHubUrlValidatity?.url && (
                      <div className="uploading-status">
                        <div className="uploading-status-body">
                          <div className="file-head">
                            <img src="./icon/link-logo.svg" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>JSON GitHub URL</span>
                              <span className="file-size">
                                {gitHubUrlValidatity?.url}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p>Open link</p>
                              <a
                                href={gitHubUrlValidatity.input}
                                target="_blank"
                                style={{ marginTop: "5px" }}
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src="./icon/open-link.svg"
                                />
                              </a>
                            </div>
                            {}
                          </div>
                        </div>
                        {}
                      </div>
                    )}
                  <div
                    className="schema-upload"
                    onClick={() => setShowFAQ(true)}
                  >
                    <p>What is the schema for creating a Drop card?</p>
                    <span>Is there an example of the schema?</span>
                  </div>
                </>
              ) : null}
              {creating ? (
                <>
                  <div
                    className="all-accordians"
                    onClick={() => {
                      setShowAccordian(
                        expandAll
                          ? showAccordian.map(() => false)
                          : showAccordian.map(() => true)
                      );
                      setExpandAll(!expandAll);
                    }}
                  >
                    <p>{expandAll ? "Collapse all" : "Expand all"}</p>
                    <img
                      src="../icon/chevron-down-accord.svg"
                      alt=""
                      style={{
                        transform: expandAll
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                  <div class="accordion">
                    <div
                      class="accordion-header"
                      onClick={() =>
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 0 ? !val : val))
                        )
                      }
                    >
                      <p>
                        {" "}
                        Enter title <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {" "}
                          {accordianStatus[0]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() =>
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 0 ? !val : val
                              )
                            )
                          }
                          style={{
                            transform: showAccordian[0]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[0] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}

                    <div
                      class="accordion-content"
                      style={{
                        transition: "1s ease",
                        display: showAccordian[0] ? "flex" : "none",
                      }}
                    >
                      <input
                        type="text"
                        name="title"
                        style={{
                          border:
                            cardDataValidation[0] === "error" &&
                            "1px solid #f87c86",
                        }}
                        value={cardData.Title === "Title" ? "" : cardData.Title}
                        placeholder="Enter title"
                        maxLength="100"
                        onChange={(e) => {
                          setCardData({ ...cardData, Title: e.target.value });

                          accordianStatus[0] !== "In progress" &&
                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 0 ? "In progress" : val
                              )
                            );
                        }}
                        onBlur={() =>
                          cardData.Title !== "Title" &&
                          cardData.Title.length > 3
                            ? setCardDataValidation(
                                cardDataValidation.map((validation, i) =>
                                  i === 0 ? "" : validation
                                )
                              )
                            : setCardDataValidation(
                                cardDataValidation.map((validation, i) =>
                                  i === 0 ? "error" : validation
                                )
                              )
                        }
                      />
                      {cardDataValidation[0] === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            Character length should be greater than 3
                          </span>
                        </div>
                      )}

                      <label style={{}} htmlFor="title">
                        Character limit is 100
                      </label>

                      <div
                        className={
                          cardData.Title !== "Title" &&
                          cardData.Title.length > 3
                            ? "next-btn"
                            : "next-btn-disabled"
                        }
                      >
                        {" "}
                        <button
                          style={{ position: "relative" }}
                          onClick={() => {
                            cardData.Title !== "Title" &&
                              cardData.Title.length > 3 &&
                              setShowAccordian(
                                showAccordian.map((val, i) =>
                                  i === 0 ? false : i === 1 ? true : false
                                )
                              );
                            if (
                              cardData.Title !== "Title" &&
                              cardData.Title.length > 3
                            )
                              setAccordianStatus(
                                accordianStatus.map((val, i) =>
                                  i === 0 ? "Completed" : val
                                )
                              );
                          }}
                        >
                          Next Section
                          <img
                            style={{}}
                            src="../icon/next-section-accord.svg"
                            alt=""
                          />
                          <img
                            className="hovered-save"
                            src="../icon/next-section-hover-accord.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="accordion">
                    {/* 2 */}
                    <div
                      class="accordion-header"
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 1 ? !val : val))
                        );
                      }}
                    >
                      <p>
                        {" "}
                        Add authors <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {accordianStatus[1]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() => {
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 1 ? !val : val
                              )
                            );

                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 1 ? "In progress" : val
                              )
                            );
                          }}
                          style={{
                            transform: showAccordian[1]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[1] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}
                    {showAccordian[1] ? (
                      <div class="accordion-content">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className="authors" htmlFor="title">
                            Author(s){" "}
                            <span className="validation-accord">*</span>
                          </label>

                          <input
                            type="text"
                            name="title"
                            placeholder="Enter name and press enter to add URL"
                            value={authorInput}
                            style={{
                              border:
                                cardDataValidation[1] === "error" &&
                                "1px solid #f87c86",
                            }}
                            onChange={(e) => {
                              setAuthorInput(e.target.value);
                              if (accordianStatus[1] !== "In progress")
                                setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 1 ? "In progress" : val
                                  )
                                );
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "Enter" &&
                                cardData.Authors.length < 4 &&
                                cardData.Authors.filter(
                                  (author) => author.Name === authorInput
                                ).length === 0
                              ) {
                                if (authorInput.length > 3) {
                                  if (
                                    cardData.Authors.length === 1 &&
                                    cardData.Authors[0].Name === "Author"
                                  )
                                    setCardData({
                                      ...cardData,
                                      Authors: [
                                        { Name: authorInput, Link: "" },
                                      ],
                                    });
                                  else
                                    setCardData({
                                      ...cardData,
                                      Authors: [
                                        ...cardData.Authors,
                                        { Name: authorInput, Link: "" },
                                      ],
                                    });
                                  setCardDataValidation(
                                    cardDataValidation.map((validation, i) =>
                                      i === 1 ? "" : validation
                                    )
                                  );
                                  setAuthorInput("");
                                } else
                                  setCardDataValidation(
                                    cardDataValidation.map((validation, i) =>
                                      i === 1 ? "error" : validation
                                    )
                                  );
                              }
                            }}
                            maxLength={25}
                          />
                          {cardDataValidation[1] === "error" && (
                            <div className="validation">
                              <img src="./icon/input-error.svg" />

                              <span className="error-message">
                                Character length should be greater than 3
                              </span>
                            </div>
                          )}
                          {cardData.Authors[0].Name !== "Author" &&
                            cardData.Authors.map((author, i) => (
                              <>
                                <div
                                  className="author-url"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <label
                                    className="accord2-lab"
                                    htmlFor="title2"
                                  >
                                    {author.Name}
                                  </label>
                                  <div style={{ display: "flex", gap: "8px" }}>
                                    <input
                                      className="accord2-inp"
                                      type="text"
                                      style={{
                                        border:
                                          authorLinkValidation[i] === "error" &&
                                          "1px solid #f87c86",
                                      }}
                                      htmlFor="title2"
                                      placeholder="Enter URL"
                                      value={author.Link}
                                      onBlur={(e) => {
                                        const urlPattern =
                                          /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}/g;

                                        if (urlPattern.test(author.Link)) {
                                          authorLinkValidation[i] = "";
                                          setAuthorLinkValidation(
                                            authorLinkValidation
                                          );
                                        } else {
                                          authorLinkValidation[i] = "error";
                                          setAuthorLinkValidation(
                                            authorLinkValidation
                                          );
                                        }

                                        if (
                                          cardData.Authors.find((author, j) =>
                                            i === j
                                              ? false
                                              : authorLinkValidation[j] ===
                                                "error"
                                              ? false
                                              : author.Link ===
                                                cardData.Authors[i].Link
                                          )
                                        )
                                          authorLinkValidation[i] = "error";
                                        setAuthorLinkValidation(
                                          authorLinkValidation
                                        );
                                        setCardData({
                                          ...cardData,
                                          Authors: cardData.Authors.map(
                                            (aut, j) =>
                                              j === i
                                                ? {
                                                    ...aut,
                                                    Link: e.target.value,
                                                  }
                                                : aut
                                          ),
                                        });
                                      }}
                                      onChange={(e) => {
                                        const urlPattern =
                                          /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}/g;

                                        if (urlPattern.test(author.Link)) {
                                          authorLinkValidation[i] = "";
                                          setAuthorLinkValidation(
                                            authorLinkValidation
                                          );
                                        } else {
                                          authorLinkValidation[i] = "error";
                                          setAuthorLinkValidation(
                                            authorLinkValidation
                                          );
                                        }
                                        if (
                                          cardData.Authors.find((author, j) =>
                                            i === j
                                              ? false
                                              : authorLinkValidation[j] ===
                                                "error"
                                              ? false
                                              : author.Link ===
                                                cardData.Authors[i].Link
                                          )
                                        )
                                          authorLinkValidation[i] = "error";
                                        setAuthorLinkValidation(
                                          authorLinkValidation
                                        );

                                        setCardData({
                                          ...cardData,
                                          Authors: cardData.Authors.map(
                                            (aut, j) =>
                                              j === i
                                                ? {
                                                    ...aut,
                                                    Link: e.target.value,
                                                  }
                                                : aut
                                          ),
                                        });

                                        if (
                                          accordianStatus[1] !== "In progress"
                                        )
                                          setAccordianStatus(
                                            accordianStatus.map((val, i) =>
                                              i === 1 ? "In progress" : val
                                            )
                                          );
                                      }}
                                    />

                                    <img
                                      src="./icon/accord-url-cross.svg"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (cardData.Authors.length === 1) {
                                          setCardData({
                                            ...cardData,
                                            Authors: [
                                              {
                                                Name: "Author",
                                                Link: "",
                                              },
                                            ],
                                          });
                                          setAuthorLinkValidation(
                                            authorLinkValidation.filter(
                                              (val, j) => j !== i
                                            )
                                          );
                                        } else {
                                          const url =
                                            cardData.Authors.findIndex(
                                              (author, j) =>
                                                i === j
                                                  ? false
                                                  : author.Link ===
                                                    cardData.Authors[i].Link
                                            );
                                          setCardData({
                                            ...cardData,
                                            Authors: cardData.Authors.filter(
                                              (auth, j) => j !== i
                                            ),
                                          });

                                          if (url && url > -1)
                                            setAuthorLinkValidation(
                                              authorLinkValidation.map(
                                                (status, j) =>
                                                  i === j
                                                    ? null
                                                    : j === url
                                                    ? ""
                                                    : status
                                              )
                                            );
                                          else
                                            setAuthorLinkValidation(
                                              authorLinkValidation.filter(
                                                (val, j) => j !== i
                                              )
                                            );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                {authorLinkValidation[i] === "error" && (
                                  <div
                                    className="validation"
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      marginLeft: "131px",
                                    }}
                                  >
                                    <img src="./icon/input-error.svg" />

                                    <span className="error-message">
                                      {cardData.Authors.find((author, j) =>
                                        i === j
                                          ? false
                                          : authorLinkValidation[j] === "error"
                                          ? false
                                          : author.Link ===
                                            cardData.Authors[i].Link
                                      )
                                        ? "URL is already added"
                                        : "URL is not valid"}
                                    </span>
                                  </div>
                                )}
                              </>
                            ))}
                        </div>
                        <ToolTip
                          title={
                            "Use a link to your preferred social media (such as LinkedIn or Google) or GitHub account where you want others to find and connect with you."
                          }
                          height={80}
                          width={250}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                              color: "#50E6FF",
                              cursor: "pointer",
                            }}
                          >
                            Which URL do I provide?
                          </p>
                        </ToolTip>

                        <div
                          className={
                            cardData.Authors.filter(
                              (author) =>
                                author.Name === "Author" ||
                                author.Name.length < 3 ||
                                author.Link.length < 3
                            ).length === 0 &&
                            !authorLinkValidation.includes("error")
                              ? "next-btn"
                              : "next-btn-disabled"
                          }
                        >
                          {" "}
                          <button
                            style={{ position: "relative" }}
                            onClick={() => {
                              cardData.Authors.filter(
                                (author) =>
                                  author.Name === "Author" ||
                                  author.Name.length < 3 ||
                                  author.Link.length < 3
                              ).length === 0 &&
                                !authorLinkValidation.includes("error") &&
                                setShowAccordian(
                                  showAccordian.map((val, i) =>
                                    i === 1 ? false : i === 2 ? true : false
                                  )
                                );

                              if (
                                cardData.Authors.filter(
                                  (author) =>
                                    author.Name === "Author" ||
                                    author.Name.length < 3 ||
                                    author.Link.length < 3
                                ).length === 0 &&
                                !authorLinkValidation.includes("error")
                              )
                                setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 1 ? "Completed" : val
                                  )
                                );
                            }}
                          >
                            Next Section
                            <img
                              style={{}}
                              src="../icon/next-section-accord.svg"
                              alt=""
                            />
                            <img
                              className="hovered-save"
                              src="../icon/next-section-hover-accord.svg"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div class="accordion">
                    {/* 3 */}
                    <div
                      class="accordion-header"
                      onClick={() =>
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 2 ? !val : val))
                        )
                      }
                    >
                      <p>
                        {" "}
                        Enter description{" "}
                        <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {accordianStatus[2]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() =>
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 2 ? !val : val
                              )
                            )
                          }
                          style={{
                            transform: showAccordian[2]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[2] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}
                    {showAccordian[2] ? (
                      <div class="accordion-content">
                        <textarea
                          id="w3review"
                          name="w3review"
                          cols="80"
                          rows="5"
                          style={{
                            border:
                              cardDataValidation[2] === "error" &&
                              "1px solid #f87c86",
                          }}
                          maxLength="250"
                          value={
                            cardData.Summary === "Card description"
                              ? ""
                              : cardData.Summary
                          }
                          autoFocus
                          placeholder="Enter description"
                          onChange={(e) => {
                            setCardData({
                              ...cardData,
                              Summary: e.target.value,
                            });
                            cardData.Summary.length > 0
                              ? setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 2 ? "In progress" : val
                                  )
                                )
                              : setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 2 ? "Not started" : val
                                  )
                                );
                          }}
                          onBlur={() =>
                            cardData.Summary.length > 5
                              ? setCardDataValidation(
                                  cardDataValidation.map((validation, i) =>
                                    i === 2 ? "" : validation
                                  )
                                )
                              : setCardDataValidation(
                                  cardDataValidation.map((validation, i) =>
                                    i === 2 ? "error" : validation
                                  )
                                )
                          }
                        />
                        {cardDataValidation[2] === "error" && (
                          <div className="validation">
                            <img src="./icon/input-error.svg" />

                            <span className="error-message">
                              Character length should be greater than 5
                            </span>
                          </div>
                        )}

                        <label htmlFor="title">Character limit is 250</label>

                        <div
                          className={
                            cardData.Summary !== "Card description" &&
                            cardData.Summary.length > 5
                              ? "next-btn"
                              : "next-btn-disabled"
                          }
                        >
                          {" "}
                          <button
                            style={{ position: "relative" }}
                            onClick={() => {
                              cardData.Summary !== "Card description" &&
                                cardData.Summary.length > 5 &&
                                setShowAccordian(
                                  showAccordian.map((val, i) =>
                                    i === 2 ? false : i === 3 ? true : false
                                  )
                                );
                              if (
                                cardData.Summary !== "Card description" &&
                                cardData.Summary.length > 5
                              )
                                setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 2 ? "Completed" : val
                                  )
                                );
                            }}
                          >
                            Next Section
                            <img
                              style={{}}
                              src="../icon/next-section-accord.svg"
                              alt=""
                            />
                            <img
                              className="hovered-save"
                              src="../icon/next-section-hover-accord.svg"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div class="accordion">
                    {/* 4 */}
                    <div
                      class="accordion-header"
                      onClick={() =>
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 3 ? !val : val))
                        )
                      }
                    >
                      <p>
                        {" "}
                        Add tags <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {accordianStatus[3]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() =>
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 3 ? !val : val
                              )
                            )
                          }
                          style={{
                            transform: showAccordian[3]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[3] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}
                    {showAccordian[3] ? (
                      <div>
                        <div
                          className="accordion-content"
                          style={{ gap: "24px" }}
                        >
                          <div
                            style={{
                              backgroundColor: "#2F3646",
                              color: "#B2CDFF",
                              width: "-webkit-fill-available",
                              paddingLeft: "10px",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                              padding: "14px 10px",
                              margin: "12px 0 10px 0",
                              borderRadius: "10px",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "start",
                              }}
                            >
                              {" "}
                              <img src="./icon/note-info.svg" style={{}} />{" "}
                              <span style={{ marginLeft: "5px" }}>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Note:
                                </span>{" "}
                                Only Azure product tags are displayed on the
                                Drops card.
                              </span>
                            </span>
                          </div>
                          {filters?.map((filter) =>
                            filter.name !== "Last Updated" ? (
                              <div
                                key={filter.name}
                                style={{ width: "-webkit-fill-available" }}
                              >
                                <div>
                                  <span>
                                    {filter.name}{" "}
                                    <span className="validation-accord">*</span>{" "}
                                  </span>
                                </div>

                                {(filter.tags.length > 6 ||
                                  filter.name === "Topics") && (
                                  <div
                                    className=""
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      src="./icon/search-icon.svg"
                                      style={{
                                        position: "absolute",
                                        left: "0",
                                        top: "50%",
                                        transform: "translate(50%, -50%)",
                                      }}
                                      alt=""
                                    />{" "}
                                    <input
                                      type="text"
                                      name="title"
                                      placeholder="Search"
                                      style={{
                                        margin: "8px 0px 8px 0px",
                                        maxWidth: "539px",
                                        paddingLeft: "30px",
                                      }}
                                      value={tagFilter[filter.name]}
                                      onChange={(e) =>
                                        setTagFilter({
                                          ...tagFilter,
                                          [filter.name]: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                )}
                                <div
                                  className="tags-content"
                                  style={{ display: `${showFAQ && "block"}` }}
                                >
                                  {" "}
                                  {filter.tags
                                    .filter((tag) =>
                                      tagFilter[filter.name]
                                        ? tag.name
                                            .toLowerCase()
                                            .includes(
                                              tagFilter[
                                                filter.name
                                              ].toLowerCase()
                                            )
                                          ? true
                                          : false
                                        : tag
                                    )
                                    .map((tag) => (
                                      <div
                                        style={{
                                          width: `${showFAQ && "auto"}`,
                                        }}
                                        className="custom-checkbox right-side-panel"
                                        key={tag.name}
                                        onClick={() => {
                                          if (cardData.Products.includes("Tag"))
                                            cardData = {
                                              ...cardData,
                                              Products:
                                                cardData.Products.filter(
                                                  (val) => val !== "Tag"
                                                ),
                                            };

                                          filter.name === "Products"
                                            ? cardData.Products?.includes(
                                                tag.name
                                              )
                                              ? setCardData({
                                                  ...cardData,
                                                  Products:
                                                    cardData.Products.filter(
                                                      (val) => val !== tag.name
                                                    ),
                                                })
                                              : cardData.Products.length < 4 &&
                                                setCardData({
                                                  ...cardData,
                                                  Products: [
                                                    ...cardData.Products,
                                                    tag.name,
                                                  ],
                                                })
                                            : filter.name === "Language"
                                            ? cardData.ProgrammingLanguage?.includes(
                                                tag.name
                                              )
                                              ? setCardData({
                                                  ...cardData,
                                                  ProgrammingLanguage:
                                                    cardData.ProgrammingLanguage.filter(
                                                      (val) => val !== tag.name
                                                    ),
                                                })
                                              : setCardData({
                                                  ...cardData,
                                                  ProgrammingLanguage: [
                                                    ...cardData.ProgrammingLanguage,
                                                    tag.name,
                                                  ],
                                                })
                                            : filter.name === "Difficulty"
                                            ? setCardData({
                                                ...cardData,
                                                Difficulty: tag.name,
                                              })
                                            : filter.name === "Topics"
                                            ? cardData.Topics?.includes(
                                                tag.name
                                              )
                                              ? setCardData({
                                                  ...cardData,
                                                  Topics:
                                                    cardData.Topics.filter(
                                                      (val) => val !== tag.name
                                                    ),
                                                })
                                              : setCardData({
                                                  ...cardData,
                                                  Topics: [
                                                    ...cardData.Topics,
                                                    tag.name,
                                                  ],
                                                })
                                            : setCardData({
                                                ...cardData,
                                                Type: tag.value,
                                              });

                                          if (
                                            accordianStatus[3] !== "In progress"
                                          )
                                            setAccordianStatus(
                                              accordianStatus.map((val, i) =>
                                                i === 3 ? "In progress" : val
                                              )
                                            );
                                        }}
                                      >
                                        <div>
                                          <input
                                            type="checkbox"
                                            id={tag.name}
                                            name="myCheckbox"
                                            value={tag.name}
                                            checked={
                                              filter.name === "Products"
                                                ? cardData.Products.includes(
                                                    tag.name
                                                  )
                                                : filter.name === "Language"
                                                ? cardData.ProgrammingLanguage.includes(
                                                    tag.name
                                                  )
                                                : filter.name === "Difficulty"
                                                ? cardData.Difficulty ===
                                                  tag.name
                                                : filter.name === "Topics"
                                                ? cardData.Topics.includes(
                                                    tag.name
                                                  )
                                                : cardData.Type === tag.value
                                            }
                                          />
                                          <label htmlFor="myCheckbox">
                                            {tag.name}
                                          </label>
                                        </div>

                                        <ToolTip
                                          title={tag.description}
                                          placement="right"
                                          width={240}
                                          height={40}
                                        >
                                          {" "}
                                          <div className="info-icon">
                                            <img
                                              className="normal-info-icon"
                                              src="./images/drops/sidebar/info-icon.svg"
                                              alt=""
                                            />

                                            <img
                                              className="hovered-info-icon"
                                              src="./images/drops/sidebar/info-icon-hover.svg"
                                              alt=""
                                            />
                                            {/* <div class="tooltip-content">{tag.description}</div> */}
                                          </div>
                                        </ToolTip>
                                      </div>
                                    ))}
                                </div>
                                {(filter.tags.length > 6 ||
                                  filter.name === "Topics") && (
                                  <div>
                                    <div className="new-tag-add">
                                      <img
                                        src="../icon/tag-icon.svg"
                                        alt="xys"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          ((filter.name === "Products" &&
                                            cardData.Products.length < 4) ||
                                            cardData.Products.filter(
                                              (tag) => tag === "Tag"
                                            ).length === 4 ||
                                            filter.name !== "Products") &&
                                            setAddNewTag({
                                              ...addNewTag,
                                              [filter.name]: [
                                                ...addNewTag[filter.name],
                                                { checked: false, input: "" },
                                              ],
                                            });
                                        }}
                                      />
                                      <p>Add tag</p>
                                    </div>
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                      }}
                                    >
                                      {addNewTag[filter.name].map(
                                        (newTag, i) => (
                                          <div className="new-tag-con">
                                            {" "}
                                            <div class="styled-checkbox">
                                              <input
                                                id={`${filter.name}${i}`}
                                                class="checkbox4"
                                                name={`${filter.name}${i}`}
                                                type="checkbox"
                                                checked={newTag?.checked}
                                                onClick={() => {
                                                  ((filter.name ===
                                                    "Products" &&
                                                    cardData.Products.length <
                                                      4) ||
                                                    cardData.Products.filter(
                                                      (tag) => tag === "Tag"
                                                    ).length === 4 ||
                                                    filter.name !==
                                                      "Products") &&
                                                    setAddNewTag({
                                                      ...addNewTag,
                                                      [filter.name]: addNewTag[
                                                        filter.name
                                                      ]?.map((tag, j) =>
                                                        j === i
                                                          ? {
                                                              ...tag,
                                                              checked:
                                                                !tag.checked,
                                                            }
                                                          : tag
                                                      ),
                                                    });
                                                }}
                                              />
                                              <label
                                                for={`${filter.name}${i}`}
                                              ></label>
                                            </div>
                                            <input
                                              placeholder="Enter tag"
                                              className="new-tag-ser"
                                              type="text"
                                              value={newTag?.input}
                                              onChange={(e) =>
                                                setAddNewTag({
                                                  ...addNewTag,
                                                  [filter.name]: addNewTag[
                                                    filter.name
                                                  ]?.map((tag, j) =>
                                                    j === i
                                                      ? {
                                                          ...tag,
                                                          input: e.target.value,
                                                        }
                                                      : tag
                                                  ),
                                                })
                                              }
                                              onKeyDown={(e) => {
                                                if (
                                                  e.key === "Enter" &&
                                                  filter.tags.filter((tag) =>
                                                    e.target.value
                                                      ? tag.name
                                                          .toLowerCase()
                                                          .includes(
                                                            e.target.value.toLowerCase()
                                                          )
                                                        ? true
                                                        : false
                                                      : tag
                                                  ).length === 0
                                                ) {
                                                  if (newTag?.checked) {
                                                    (filter.name ===
                                                      "Products" &&
                                                      cardData.Products.length <
                                                        4) ||
                                                    cardData.Products.filter(
                                                      (tag) => tag === "Tag"
                                                    ).length === 4
                                                      ? setCardData({
                                                          ...cardData,
                                                          Products:
                                                            cardData.Products.includes(
                                                              "Tag"
                                                            )
                                                              ? [e.target.value]
                                                              : [
                                                                  ...cardData.Products,
                                                                  e.target
                                                                    .value,
                                                                ],
                                                        })
                                                      : filter.name ===
                                                        "Language"
                                                      ? setCardData({
                                                          ...cardData,
                                                          ProgrammingLanguage: [
                                                            ...cardData.ProgrammingLanguage,
                                                            e.target.value,
                                                          ],
                                                        })
                                                      : filter.name === "Topics"
                                                      ? setCardData({
                                                          ...cardData,
                                                          Topics: [
                                                            ...cardData.Topics,
                                                            e.target.value,
                                                          ],
                                                        })
                                                      : setCardData({
                                                          ...cardData,
                                                          Topics: [
                                                            ...cardData.Topics,
                                                            e.target.value,
                                                          ],
                                                        });
                                                  }

                                                  setFilters(
                                                    filters.map((filterData) =>
                                                      filterData.name ===
                                                      filter.name
                                                        ? {
                                                            ...filterData,
                                                            tags: [
                                                              ...filterData.tags,
                                                              {
                                                                name: e.target
                                                                  .value,
                                                              },
                                                            ],
                                                          }
                                                        : filterData
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                            <img
                                              src="./icon/accord-url-cross.svg"
                                              alt=""
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                setAddNewTag({
                                                  ...addNewTag,
                                                  [filter.name]: addNewTag[
                                                    filter.name
                                                  ]?.filter(
                                                    (tag, j) => j !== i
                                                  ),
                                                })
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "20px 20px 20px 20px",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <ToolTip
                              title={
                                "Add tags to improve discoverability and filtering. For example, tags make it easy to find projects based on related topics. Tags also help categorize projects within the Arc Jumpstart community, making it easier for contributors and users with similar interests to connect with your work."
                              }
                              height={115}
                              width={280}
                            >
                              <b className="example-text">
                                Why should I add tags?
                              </b>
                            </ToolTip>
                            <ToolTip
                              title={
                                "When adding tags, choose terms that best describe the key features and core functionalities of your project. Include any technologies used, relevant frameworks, and concepts that are central to your drop."
                              }
                              height={80}
                              width={280}
                            >
                              <b className="example-text">
                                What type of tags should I add?
                              </b>
                            </ToolTip>
                          </div>
                          <div
                            className={
                              cardData.Products.length > 0 &&
                              cardData.ProgrammingLanguage.length > 0 &&
                              cardData.Topics.length > 0 &&
                              cardData.Type &&
                              cardData.Difficulty
                                ? "next-btn"
                                : "next-btn-disabled"
                            }
                          >
                            {" "}
                            <button
                              style={{ position: "relative" }}
                              onClick={() => {
                                if (
                                  githubPrefrence === "personal" &&
                                  !sidePanelCreationStatusCompletd
                                ) {
                                  setSidePanelDropCreation("error");
                                  setPersonalGitHubUrlValidity({
                                    status: "error",
                                    message: "Enter valid URL",
                                  });
                                } else setCreatingDrop(false);
                                if (
                                  cardData.Products.length > 0 &&
                                  cardData.ProgrammingLanguage.length > 0 &&
                                  cardData.Topics.length > 0 &&
                                  cardData.Type &&
                                  cardData.Difficulty
                                )
                                  setAccordianStatus(
                                    accordianStatus.map((val, i) =>
                                      i === 3 ? "Completed" : val
                                    )
                                  );
                              }}
                            >
                              Next Section:Side panel
                              <img
                                style={{}}
                                src="../icon/next-section-accord.svg"
                                alt=""
                              />
                              <img
                                className="hovered-save"
                                src="../icon/next-section-hover-accord.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <ToolTip
                    title={
                      <img
                        className="example-test-img"
                        src="./images/drops/dropExample.svg"
                        height={500}
                        width={500}
                      />
                    }
                    height={500}
                    width={410}
                  >
                    <b className="example-text"> Show me an example.</b>
                  </ToolTip>
                </>
              ) : null}
            </div>
            {showFAQ ? <CardSchema onClose={handleOnCloseFAQ} /> : null}
          </>
        ) : (
          <SidePanel
            cardData={cardData}
            setMdText={setMdText}
            accordianStatus={sidePanelAccordianStatus}
            setAccordianStatus={setSidePanelAccordianStatus}
            setCoverImage={setCoverImage}
            setCoverImageName={setCoverImageName}
            setResourceImage={setResourceImage}
            setResourceImageName={setResourceImageName}
            setArtifactImage={setArtifactImage}
            setArtifactImageName={setArtifactImageName}
            setSidePanelCreationStatusCompleted={
              setSidePanelCreationStatusCompleted
            }
            sidePanelDropCreation={sidePanelDropCreation}
            sidePaneluploadOption={sidePaneluploadOption}
            setSidePanelUploadOption={setSidePanelUploadOption}
            sidePanelCreating={sidePanelCreating}
            setSidePanelCreating={setSidePanelCreating}
            usingExtarnalDropSchema={usingExtarnalDropSchema}
            githubPrefrence={githubPrefrence}
            mdLink={mdLink}
            mdText={mdText}
            isMdFileUploaded={isMdFileUploaded}
            setIsMdFileUploaded={setIsMdFileUploaded}
          />
        )}
      </div>

      <Modal
        isOpen={completeCreation}
        className="creation-modal-section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0)",

            backdropFilter: "none",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          },

          content: {
            backgroundColor: "#383838",
          },
        }}
      >
        <div className="creation-modal">
          <h4> Creating your drop</h4>
          <img src="./icon/creation-drop-logo.svg" alt="" />
          <div class="progress-bar-container">
            <div
              class="progress-bar"
              id="progress-bar"
              style={{
                width: `${!progress ? progressCalculate * 5 : progress}%`,
              }}
            ></div>
            <span class="time-remaining">{seconds} seconds remaining</span>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showAfterCreation}
        className="creation-modal-section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0)",

            backdropFilter: "none",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          },

          content: {
            backgroundColor: "#383838",
          },
        }}
      >
        <div className="creation-modal creation-modal-success">
          <h4> Created your drop</h4>
          <img
            src="./animation/loading_animation.gif"
            alt=""
            style={{ height: "150px", width: "150px" }}
          />
          <div
            className="success-drop-text repo"
            style={{
              background: createdPullRequest === null && "#462F2F",
              color: createdPullRequest === null && "#FF8383",
            }}
          >
            {" "}
            <img
              src={
                createdPullRequest === null
                  ? "./icon/error-save-icon.svg"
                  : "./icon/success-save-icon.svg"
              }
              alt=""
            />
            {createdPullRequest === null ? (
              <div>
                <span
                  className="success-drop-title"
                  style={{ color: "#FF8383" }}
                >
                  Error:
                </span>{" "}
                Error: Your Drop could not be created due to an issue. Please
                try again later. If the problem persists, contact support for
                assistance.
              </div>
            ) : (
              <div>
                <span className="success-drop-title" style={{}}>
                  Success:
                </span>{" "}
                Your Drop has been successfully created and is now pending
                review and approval. You will receive notification via email
                regarding the next steps in the process. In the meantime, you
                can access your Drops below. Thank you for your contribution!
                <div className="created-link">
                  {" "}
                  <input
                    type="text"
                    name=""
                    style={{ color: "white" }}
                    id=""
                    readOnly
                    value={createdPullRequest}
                  />{" "}
                  <img
                    onClick={() =>
                      navigator.clipboard.writeText(createdPullRequest)
                    }
                    style={{ cursor: "pointer" }}
                    src="./icon/copy-square.svg"
                    width="24px"
                    height="24px"
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>

          <div className="modal-btns">
            <button
              className="submit-modal"
              onClick={() => window.location.reload()}
            >
              Submit another Drop
            </button>

            <Link to="/azure_jumpstart_drops">
              <button className="back-to-btn">Back to main page</button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DropCreation;
